import { createSlice } from "@reduxjs/toolkit";
import { UserStateInterface } from "../../typesDefs/interfaces/userState";
import { MenuItem } from "@mantine/core/lib/Menu/MenuItem/MenuItem";

const initialState:UserStateInterface = {
    isLoading: false,
    isAuth: false,
    userToken:'',
    userAuthError: "",
    userData:null,
    isModuleLoading:false,
    isModuleLoaded:false,
    userAllowedModules:[],
    selectedModule:null,
    moduleAllowedError:'',
    isCompanyLoading:false,
    isCompanyLoaded:false,
    userAllowedCompanies:[],
    selectedCompany:null,
    companyAllowedError:'',
    isMenuLoading:false,
    isMenuLoaded:false,
    userMenus:[],
    selectedMenuModule:null,
    selectedMenu: null,
    menuLoadingError:'',
    updateCompanyChangeLoading:false,
    updateCompanyChangeError:''
}

export const loginSlice = createSlice({
    name:'login',
    initialState,
    reducers: {
        loginPending: (state) => {
            state.isLoading = true;
        },
        loginSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.isAuth = true;
            state.userToken = payload.userToken;
            state.userAuthError = "";
            state.userData = payload.userData ?? null;
            localStorage.setItem('userToken',payload.userToken);
        },
        loginFail: (state, { payload }) => {
            state.isLoading = false;
            state.isAuth = false;
            state.userToken = '';
            state.userData = null;
            state.userAuthError = payload;
        },
        loginReset:(state) => initialState,
        // For User Module Access
        modulePending: (state) => {
            state.isModuleLoading = true;
        },
        moduleSuccess: (state, {payload}) => {
            state.isModuleLoading = false;
            state.isModuleLoaded = true;
            state.userAllowedModules = payload.userAllowedModules;
            state.selectedModule = payload.selectedModule;
            state.moduleAllowedError = "";
        },
        moduleFail: (state, { payload }) => {
            state.isModuleLoading = false;
            state.isModuleLoaded = false;
            state.userAllowedModules = [];
            state.moduleAllowedError = payload;
        },
        setSelectedModule:(state, { payload }) => {
            state.selectedModule = payload.selectedModule;
            state.isMenuLoaded = false;
            state.isMenuLoading = false;
        },
        // For User Company Access
        getCompanyPending: (state) => {
            state.isCompanyLoading = true;
        },
        getCompanySuccess: (state, {payload}) => {
            state.isCompanyLoading = false;
            state.isCompanyLoaded = true;
            state.userAllowedCompanies = payload.userAllowedCompanyDetails;
            state.selectedCompany = payload.userSelectedCompanyDetails;
            state.companyAllowedError = "";
        },
        getCompanyFail: (state, { payload }) => {
            state.isCompanyLoading = false;
            state.isCompanyLoaded = false;
            state.userAllowedCompanies = [];
            state.selectedCompany = null;
            state.companyAllowedError = payload;
        },
        // For User Menu Access
        getMenuPending: (state) => {
            state.isMenuLoading = true;
            state.isMenuLoaded = false;
        },
        getMenuSuccess: (state, {payload}) => {
            state.isMenuLoading = false;
            state.isMenuLoaded = true;
            state.userMenus = payload.userMenus;
            state.selectedMenuModule = payload.userMenus[0] ?? {};
            state.menuLoadingError = "";
        },
        getMenuFail: (state, { payload }) => {
            state.isMenuLoading = false;
            state.isMenuLoaded = false;
            state.userMenus = [];
            state.selectedMenuModule = {};
            state.menuLoadingError = payload;
        },
        setSelectedMenuModule:(state,{payload}) => {
            state.selectedMenuModule = payload.selectedMenuModule ?? {};
        },
        setSelectedMenu:(state,{payload}) => {
            state.selectedMenu = payload.selectedMenu;
        },
        // For User Menu Access
        updateCompanySelectionChangePending: (state) => {
            state.updateCompanyChangeLoading = true;
        },
        updateCompanySelectionChangeSuccess: (state, {payload}) => {
            state.updateCompanyChangeLoading = false;
            state.selectedCompany = payload.selectedCompany;
            state.updateCompanyChangeError = "";
        },
        updateCompanySelectionChangeFail: (state, { payload }) => {
            state.updateCompanyChangeLoading = false;
            state.updateCompanyChangeError = payload;
        },
    }
})

const { reducer, actions } = loginSlice;

export const { loginPending, loginSuccess, loginFail, loginReset, 
    modulePending, moduleSuccess, moduleFail, setSelectedModule,
    getCompanyPending, getCompanySuccess, getCompanyFail,
    getMenuPending, getMenuSuccess, getMenuFail,
    setSelectedMenuModule, setSelectedMenu,
    updateCompanySelectionChangePending, updateCompanySelectionChangeSuccess, updateCompanySelectionChangeFail } = actions;

export default reducer;