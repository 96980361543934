import { IconPlus, IconEdit, IconTrash, IconPrinter, IconTableExport, 
    IconMailForward, IconArrowsExchange2, IconDownload, IconUpload,
    IconRefreshDot, IconCurrentLocation, IconThumbUp, IconEditCircle,
    IconTrashX, IconClipboardPlus, IconArrowMoveRight, IconCopy,
    IconRefresh } from "@tabler/icons-react";

export const menuActionData = [
    { code:0, label: "Reload Data", icon: IconRefresh,},
    { code:1, label: "Add", icon: IconPlus,   },
    { code:2, label: "Edit", icon: IconEdit,  },
    { code:3, label: "Delete", icon: IconTrash,},
    { code:4, label: "Print", icon: IconPrinter,},
    { code:5, label: "Excel Export", icon: IconTableExport,},
    { code:6, label: "Email", icon: IconMailForward,},
    { code:7, label: "Toggle Status", icon: IconArrowsExchange2,},
    { code:8, label: "Download", icon: IconDownload,},
    { code:9, label: "Upload", icon: IconUpload,},
    { code:10, label: "Reset Password", icon: IconRefreshDot,},
    { code:11, label: "Shipping Location", icon: IconCurrentLocation,},
    { code:12, label: "Approve", icon: IconThumbUp,},
    { code:13, label: "Approve Edit", icon: IconEditCircle,},
    { code:14, label: "Approve Delete", icon: IconTrashX,},
    { code:15, label: "Add Job Cards", icon: IconClipboardPlus,},
    { code:16, label: "Issue Job Cards", icon: IconArrowMoveRight,},
    { code:17, label: "Copy", icon: IconCopy,},
    { code:18, label: "Packing Summary", icon: IconRefresh,},
    { code:19, label: "Packing Sticker", icon: IconRefresh,},
    { code:20, label: "GRN", icon: IconRefresh,},
    { code:21, label: "Verification", icon: IconRefresh,},
    { code:22, label: "Delivery Challan", icon: IconRefresh,},
    { code:23, label: "Barcode Sticker", icon: IconRefresh,},
    { code:24, label: "Inspection", icon: IconRefresh,},
    { code:25, label: "Dispatch Summary", icon: IconRefresh,},
    { code:26, label: "Bundling", icon: IconRefresh,},
    { code:27, label: "Voucher No. Editable", icon: IconRefresh,},
    { code:28, label: "Date Editable", icon: IconRefresh,},
    { code:29, label: "Edit Job Cards", icon: IconRefresh,},
    { code:30, label: "Delete Job Card", icon: IconRefresh,},
    { code:31, label: "Print Job Cards", icon: IconRefresh,},
    { code:32, label: "Allow Rate Modification", icon: IconRefresh,},
    { code:33, label: "Allow Discount Modification", icon: IconRefresh,},
    { code:34, label: "ASN Tracking Report", icon: IconRefresh,},
    { code:35, label: "Import", icon: IconRefresh,},
    { code:36, label: "Log", icon: IconRefresh,},
    { code:37, label: "Only Self Reminder", icon: IconRefresh,},
    { code:38, label: "Manage Users", icon: IconRefresh,},
    { code:39, label: "Allow Only Self Entry", icon: IconRefresh,},
    { code:40, label: "Take Task Ownership", icon: IconRefresh,},
]