import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  createStyles,
  Box,
  Button,
  Divider,
  FocusTrap,
  Group,
  Image,
  Paper,
  PasswordInput,
  Text,
  TextInput,
} from "@mantine/core";
import { appAuthAction, appTokenAuthAction } from "../../redux/actions/appAuthActions";
import { LoginDetailsInterface } from "../../typesDefs/interfaces/apiPayload/loginDetails";
import { RootState } from "../../redux/store";
import { useAppDispatch } from "../../redux/hooks";
import { userAuthAction } from "../../redux/actions/userAuthActions";
import { useForm } from "@mantine/form";

interface LoginProps {
  setFormType: React.Dispatch<React.SetStateAction<number>>
}

const useStyles = createStyles((theme) => ({
  input: {
    input: {
      fontSize: "1.2rem",
      padding: "1rem",
    },
    label: {
      fontSize: "1rem",
      marginBottom: "0.5rem",
    },
  },
  loginError: {
    fontSize:"1rem",
    margin:"0.5rem",
    textAlign:"center",
    justifyContent:"center"
  }
}))

const LoginForm: React.FC<LoginProps> = ({ setFormType }) => {
  const { classes } = useStyles();
  const { appToken } = useSelector((state:RootState) => state.app);
  const { isLoading, userAuthError } = useSelector((state:RootState) => state.user);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from?.pathname || "/";
  const validateAppToken = async () => {
    if(typeof appToken === 'string' && appToken !== ''){
      await dispatch(appTokenAuthAction(appToken));
    }else{
      await dispatch(appAuthAction());
    }
  }
  useEffect(() => {
    validateAppToken();
  },[])
  const form = useForm({
    initialValues: {
      clientId: "",
      username: "",
      password: "",
    },
    validate: {
      clientId: (value) =>
        value.length === 4 && Number(value) > 0 ? null : "Invalid Client Id",
    },
  })

  const formValidationHandler = (values: LoginDetailsInterface) => {
    const loginValidation = async (loginDetails:LoginDetailsInterface,appToken:string) => {
      const response = await dispatch(userAuthAction(loginDetails,appToken));
      if(response?.status === 'Ok'){
          navigate(from, { replace: true });
      }
      
  }
  if(appToken === ''){
    validateAppToken();
  }
  loginValidation(values,appToken)
  }
  return (
    <Paper withBorder shadow="md" p={30} mt={30} radius="md" color="brand">
      <Image src={"/assets/images/shivamerp-logo.png"} />
      <Divider my="sm" />
      <Box className={classes.loginError}>
          <Text c="red.7" fz="md" fw={600} ta="center">{userAuthError}</Text>
      </Box>
      <FocusTrap active={true}>
        <form
          onSubmit={form.onSubmit((values) => formValidationHandler(values))}
        >
          <TextInput
            data-autofocus
            label="Client Id"
            placeholder="Enter Your Client Id"
            required
            mt="md"
            withAsterisk
            className={classes.input}
            size="xl"
            type="number"
            {...form.getInputProps("clientId")}
          />
          <TextInput
            label="Username"
            placeholder="Enter Your User Name"
            required
            mt="md"
            withAsterisk
            className={classes.input}
            size="xl"
            {...form.getInputProps("username")}
          />
          <PasswordInput
            label="Password"
            placeholder="Enter Your password"
            required
            mt="md"
            withAsterisk
            className={classes.input}
            size="xl"
            {...form.getInputProps("password")}
          />
          <Group position="apart" mt="lg">
            <Button variant="subtle" onClick={() => setFormType(2)}>
              Forgot password?
            </Button>
          </Group>
          <Button fullWidth mt="xl" type="submit" loading={isLoading}>
            Sign in
          </Button>
        </form>
      </FocusTrap>
    </Paper>
  )
}

export default LoginForm
