import { useState } from "react"
import { Accordion, createStyles, Navbar, Overlay, rem } from "@mantine/core"
import UserAllowedModule from "./UserAllowedModule"
import UserAllowedMenus from "./UserAllowedMenus"
import UserAllowedCompanies from "../header/UserAllowedCompanyDetails"
import UserDetails from "../header/UserDetails"

const useStyles = createStyles((theme) => ({
  module: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: rem(100),
    borderBottom: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
  },
  wrapper: {
    display: "flex",
  },
  footer: {
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
    marginBottom: `4rem`,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
    padding: "1.5rem",

    [`@media (min-width: 51.2em)`]: {
      display: "none",
    },
  },
  accordionControl: {
    backgroundColor: theme.fn.variant({
      variant: "light",
      color: theme.primaryColor,
    }).background,
  },
}))

export function DoubleNavbar() {
  const { classes, cx } = useStyles()
  const [isModuleOpened, setIsModuleOpened] = useState(false)

  return (
    <Navbar height={"100%"} width={{ sm: 400, xl: 400 }}>
      <Navbar.Section className={classes.module}>
        <UserAllowedModule
          isModuleOpened={isModuleOpened}
          setIsModuleOpened={setIsModuleOpened}
        />
      </Navbar.Section>
      <Navbar.Section grow className={classes.wrapper}>
        <UserAllowedMenus />
        {isModuleOpened && <Overlay color="#000" opacity={0.75} />}
      </Navbar.Section>
      <Navbar.Section className={classes.footer}>
        <Accordion variant="separated">
          <Accordion.Item value="company">
            <Accordion.Control className={classes.accordionControl}>
              Company Details
            </Accordion.Control>
            <Accordion.Panel>
              <UserAllowedCompanies />
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="user" className={classes.accordionControl}>
            <Accordion.Control>User Details</Accordion.Control>
            <Accordion.Panel>
              <UserDetails />
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Navbar.Section>
    </Navbar>
  )
}
