import { createSlice } from "@reduxjs/toolkit";
import { IFormState } from "../../../../../typesDefs/interfaces/modules/ocgc/masters/packingTypeMaster";

const initialState:IFormState = {
    isEditMode:false,
    selectedRowId:null,
    selectedRow:null
}

export const packingTypeSlice = createSlice({
    name:'packingType',
    initialState,
    reducers:{
        setEditMode: (state, {payload}) => {
            state.isEditMode = true;
            state.selectedRowId = payload.packingTypeId;
            state.selectedRow = payload
        },
        resetEditMode: (state) => {
            state.isEditMode = false;
            state.selectedRowId = null;
            state.selectedRow = null;
        }
    }
});

const { reducer , actions } = packingTypeSlice;

export const { setEditMode, resetEditMode } = actions;

export default reducer;

