import { ComponentType } from "react"

type FallbackProps = {
  error: any
  resetErrorBoundary: (...args: any[]) => void
}

const Fallback: ComponentType<FallbackProps> = ({
  error,
  resetErrorBoundary,
}) => {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
    </div>
  )
}

export default Fallback
