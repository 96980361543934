import { loginPending, loginSuccess, loginFail, 
    modulePending, moduleSuccess, moduleFail,
    getCompanyPending, getCompanySuccess, getCompanyFail, 
    getMenuPending, getMenuSuccess, getMenuFail, loginReset,
    updateCompanySelectionChangePending,updateCompanySelectionChangeSuccess, 
    updateCompanySelectionChangeFail } from "../slice/userSlice";
import { userAuth, userTokenAuth, userLogout, getUserModuleAccess, 
    getUserCompanyAccess, getUserMenuAccess, updateCompanySelection } from "../../service/apiCalls/userApiCalls";
import { AppDispatch } from "../store";
import {AxiosError} from "axios";
import { LoginDetailsInterface } from "../../typesDefs/interfaces/apiPayload/loginDetails";

interface UpdateCompanySelectionChangeProps {
    companyId:string,
    financialYearId:string,
    branchId:string
}

export const userAuthAction = (loginDetails:LoginDetailsInterface, appToken:string) => async (dispatch:AppDispatch) => {
    try{
        dispatch(loginPending());
        const result = await userAuth(loginDetails,appToken);
        if(result.status === 'Ok'){
            dispatch(loginSuccess({userToken:result.data.userToken,userData:result.data.userData}));
        }else{
            dispatch(loginFail(result.errorMessage));
        }
        return result;
    }catch(error){
        const err = error as AxiosError
        dispatch(loginFail(err.message ?? 'Token API Failed'));
    }
}

export const userTokenAuthAction = (userToken:string) => async (dispatch:AppDispatch) => {
    try{
        dispatch(loginPending());
        return await userTokenAuth({},userToken);
    }catch(error){
        const err = error as AxiosError
        dispatch(loginFail(err.message ?? 'User Token Authentication API Failed'));
    }
}

export const userLogoutAction = (userToken:string) => async (dispatch:AppDispatch) => {
    try{
        dispatch(loginPending());
        const logoutResponse  = await userLogout({},userToken);
        if(logoutResponse.status === 'Ok'){
            dispatch(loginReset());
        }
        return {status:logoutResponse.status, responseStatus: logoutResponse.data.responseStatus, responseCode: logoutResponse.data.responseCode, message:logoutResponse.data.message};
    }catch(error){
        const err = error as AxiosError
        dispatch(loginFail(err.message ?? 'User Logout API Failed'));
    }
}


export const getUserModuleAccessAction = (userToken:string) => async (dispatch:AppDispatch) => {
    try{
        dispatch(modulePending());
        const result = await getUserModuleAccess(userToken);
        if(result.status === 'Ok'){
            dispatch(moduleSuccess({userAllowedModules:result.data.userAllowedModuleDetails.allowedModules,selectedModule:result.data.userAllowedModuleDetails.selectedModule}));
        }else{
            dispatch(moduleFail(result.errorMessage));
        }
        return result;
    }catch(error){
        const err = error as AxiosError
        dispatch(moduleFail(err.message ?? 'Get Module API Failed'));
    }
}

export const getUserCompanyAccessAction = (userToken:string) => async (dispatch:AppDispatch) => {
    try{
        dispatch(getCompanyPending());
        const result = await getUserCompanyAccess(userToken);
        if(result.status === 'Ok'){
            dispatch(getCompanySuccess({userAllowedCompanyDetails:result.data.userAllowedCompanyDetails,userSelectedCompanyDetails:result.data.userSelectedCompanyDetails}));
        }else{
            dispatch(getCompanyFail(result.errorMessage));
        }
        return result;
    }catch(error){
        const err = error as AxiosError
        dispatch(getCompanyFail(err.message ?? 'Get Company Access API Failed'));
    }
}


export const getUserMenuAccessAction = (userToken:string,forModuleId:string) => async (dispatch:AppDispatch) => {
    try{
        dispatch(getMenuPending());
        const result = await getUserMenuAccess(userToken,{forModuleId});
        if(result.status === 'Ok'){
            dispatch(getMenuSuccess({userMenus:result.data.userMenus}));
        }else{
            dispatch(getMenuFail(result.errorMessage));
        }
        return result;
    }catch(error){
        const err = error as AxiosError
        dispatch(getMenuFail(err.message ?? 'Get Menu Access API Failed'));
    }
}

export const updateCompanySelectionChangeAction = (userToken:string,payload : UpdateCompanySelectionChangeProps) => async (dispatch:AppDispatch) => {
    try{
        dispatch(updateCompanySelectionChangePending());
        const result = await updateCompanySelection(userToken,payload);
        if(result.status === 'Ok'){
            dispatch(updateCompanySelectionChangeSuccess({selectedCompany:result.data.userSelectedCompanyDetails}));
        }else{
            dispatch(updateCompanySelectionChangeFail(result.errorMessage));
        }
        return result;
    }catch(error){
        const err = error as AxiosError
        dispatch(updateCompanySelectionChangeFail(err.message ?? 'Update Company Selection Update API Failed'));
    }
}