import {
  createStyles,
  Button,
  Divider,
  Group,
  Modal,
  MantineNumberSize,
  Text,
} from "@mantine/core"

interface IModalButtonLabelsProps {
  confirm?: string
  cancel?: string
}
interface IConfirmationModalProps {
  title: string
  children: React.ReactNode
  labels?: IModalButtonLabelsProps
  size?: MantineNumberSize
  opened: boolean
  onCancel: () => void
  onConfirm: () => void
  onClose: () => void
}

const useStyles = createStyles((theme) => ({}))

const ConfirmationModal: React.FC<IConfirmationModalProps> = (props) => {
  const {
    title,
    children,
    labels,
    size,
    opened,
    onCancel,
    onConfirm,
    onClose = () => {},
  } = props
  const { classes, theme, cx } = useStyles()

  const getTitle = () => {
    if (typeof title === "string") {
      return (
        <Text fz="lg" fw={600} c={theme.colors[theme.primaryColor][6]}>
          {title}
        </Text>
      )
    }
    return title
  }

  return (
    <>
      <Modal
        opened={opened}
        onClose={onClose}
        title={getTitle()}
        size={size}
        centered
      >
        <Divider my="sm" size="xs" />
        {children}
        <Divider my="sm" />
        <Group position="right" mt="lg">
          <Button
            variant="outline"
            onClick={() => {
              onCancel()
            }}
          >
            {labels?.confirm ?? "Cancel"}
          </Button>
          <Button
            variant="filled"
            onClick={() => {
              onConfirm()
            }}
          >
            {labels?.confirm ?? "Confirm"}
          </Button>
        </Group>
      </Modal>
    </>
  )
}

export default ConfirmationModal
