import { z } from "zod"
import {
  createStyles,
  rem,
  Box,
  Button,
  Center,
  FocusTrap,
  Group,
  Image,
  Paper,
  TextInput,
} from "@mantine/core"
import { IconArrowLeft } from "@tabler/icons-react"
import { useForm, zodResolver } from "@mantine/form"

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: rem(26),
    fontWeight: 900,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },
  input: {
    input: {
      fontSize: "1.2rem",
      padding: "1rem",
    },
    label: {
      fontSize: "1rem",
      marginBottom: "0.5rem",
    },
  },
  controls: {
    [theme.fn.smallerThan("xs")]: {
      flexDirection: "column-reverse",
    },
  },

  control: {
    [theme.fn.smallerThan("xs")]: {
      width: "100%",
      textAlign: "center",
    },
  },

  companyLogo: {
    width: 420,
  },
}))

interface ForgotPasswordFormProps {
  setFormType: React.Dispatch<React.SetStateAction<number>>
}

interface ForgotPasswordForm {
  clientId: string
  userName: string
  mobileNo: string
}

const schema = z.object({
  clientId: z.string().length(4, { message: "Invalid Client Id" }),
  userName: z.string({
    required_error: "Name is required",
    invalid_type_error: "Name must be a string",
  }),
  mobileNo: z.string().length(10, { message: "10 Digit Mobile No. Required" }),
})

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
  setFormType,
}) => {
  const { classes } = useStyles()
  const form = useForm({
    initialValues: {
      clientId: "",
      userName: "",
      mobileNo: "",
    },
    validate: zodResolver(schema),
  })

  const formValidationHandler = (values: ForgotPasswordForm) => {
    // console.log(values)
  }

  return (
    <Paper withBorder shadow="md" p={30} mt={30} radius="md" color="brand">
      <Image src={"/assets/images/shivamerp-logo.png"} />
      <FocusTrap active={true}>
        <form
          onSubmit={form.onSubmit((values) => formValidationHandler(values))}
        >
          <TextInput
            label="Client Id"
            placeholder="Enter Your Client Id"
            required
            mt="md"
            withAsterisk
            className={classes.input}
            size="xl"
            type="number"
            data-autofocus
            {...form.getInputProps("clientId")}
          />
          <TextInput
            label="Username"
            placeholder="Enter Your User Name"
            required
            mt="md"
            withAsterisk
            className={classes.input}
            size="xl"
            {...form.getInputProps("userName")}
          />
          <TextInput
            label="Mobile No."
            placeholder="Enter Your 10 Digit Mobile No."
            required
            mt="md"
            withAsterisk
            className={classes.input}
            size="xl"
            type="number"
            {...form.getInputProps("mobileNo")}
          />
          <Group position="apart" mt="lg" className={classes.controls}>
            <Button variant="subtle" onClick={() => setFormType(1)}>
              <Center inline>
                <IconArrowLeft size={rem(12)} stroke={1.5} />
                <Box ml={5}>Back to the login page</Box>
              </Center>
            </Button>
          </Group>
          <Button fullWidth mt="xl" type="submit">
            Reset password
          </Button>
        </form>
      </FocusTrap>
    </Paper>
  )
}

export default ForgotPasswordForm
