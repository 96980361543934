import { useEffect, Suspense } from "react";
import { useRoutes } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Container, LoadingOverlay } from "@mantine/core";
import { routes } from "./service/routes/routes";
import { RootState } from "./redux/store";
import { useAppDispatch } from "./redux/hooks";
import { appAuthAction, appTokenAuthAction } from './redux/actions/appAuthActions';

const Entry = () => {
  const {isLoading, appToken} = useSelector((state:RootState) => state.app);
  const dispatch = useAppDispatch();
  useEffect(() => {
    const appTokenAuth = async () => {
      const localAppToken = localStorage.getItem('appToken');
      const token = appToken !== '' ? appToken : localAppToken ?? '';
      if(token !== ''){
        const result = await dispatch(appTokenAuthAction(token));
        if(result.status !== 'Ok'){
          await dispatch(appAuthAction());
        }
      }else{
        await dispatch(appAuthAction());
      }
    }
    if(!isLoading)
      appTokenAuth();
  },[]);
  let element = useRoutes(routes);
  return (
  <>
    <Box pos="relative">
      <LoadingOverlay visible={isLoading} overlayBlur={2} />
      <Suspense fallback={<LoadingOverlay visible={true} overlayBlur={2} />}>
        <Container fluid>{element}</Container>
      </Suspense>
    </Box>
  </>)
};

export default Entry;
