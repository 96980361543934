import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import {
  AppShell,
  useMantineTheme,
} from '@mantine/core';
import PageHeader from './header/PageHeader';
import { DoubleNavbar } from './navbar/Navbar';
import { useAppDispatch } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import { getUserModuleAccessAction, getUserMenuAccessAction } from "../../redux/actions/userAuthActions";
import { setSelectedModule,setSelectedMenuModule,setSelectedMenu } from '../../redux/slice/userSlice';


const Home = () => {
  const theme = useMantineTheme();
  const {isNavbarOpen} = useSelector((state:RootState) => state.app);
  const { isAuth, userToken, userAllowedModules, selectedModule, userMenus, selectedMenuModule } = useSelector((state: RootState) => state.user);
  const location = useLocation();
  const dispatch = useAppDispatch();
  useEffect(() => {
    const currentRouteModulePath = `/${location.pathname.split('/')[1]}`;
    const getUserAllowedModules = async () => {
      if (isAuth) await dispatch(getUserModuleAccessAction(userToken))
    }
    if(selectedModule === null && userAllowedModules.length === 0){
      getUserAllowedModules();
    }
    if(selectedModule?.path !== currentRouteModulePath){
      const selectModuleFromRoute = userAllowedModules.find((mod) => mod.path === currentRouteModulePath);
      if(selectModuleFromRoute){
        dispatch(setSelectedModule({selectedModule:selectModuleFromRoute}));
        dispatch(getUserMenuAccessAction(userToken,selectModuleFromRoute?.moduleCode ?? ""));
      }
    }
  },[userAllowedModules]);

  useEffect(()=>{
    const currentRouteMenuModulePath = `/${location.pathname.split('/')[2]}`;
    if(userMenus.length > 0){
      const selectMenuModuleFromRoute = userMenus.find((menuMod) => menuMod.path === currentRouteMenuModulePath);
      if(selectMenuModuleFromRoute){
        dispatch(setSelectedMenuModule({ selectedMenuModule: selectMenuModuleFromRoute }))
        const currentRouteMenuPath = `/${location.pathname.split('/')[3]}`;
        if(selectMenuModuleFromRoute?.items && selectMenuModuleFromRoute?.items.length){
          const selectedMenuFromRoute = selectMenuModuleFromRoute?.items.find((menu) => menu.path === currentRouteMenuPath);
          if(selectedMenuFromRoute){
            dispatch(setSelectedMenu({ selectedMenu: selectedMenuFromRoute }));
          }
        }
      }
    }

  },[userMenus])
  return (
    <div className="main--container">
      <AppShell
      styles={{
        main: {
          background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
        },
      }}
      header={<PageHeader />}
      navbar={isNavbarOpen ? <DoubleNavbar /> : undefined}
    >
      <Outlet />
    </AppShell>
    </div>
  );
};

export default Home;
