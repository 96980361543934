import React, { useEffect } from "react";
import { useAppSelector, useAppDispatch } from '../redux/hooks';
import { Navigate, useLocation, useNavigate  } from "react-router-dom";
import { loginReset, loginSuccess } from "../redux/slice/userSlice";
import { userTokenAuthAction } from "../redux/actions/userAuthActions";
import { DefaultLayout } from "./DefaultLayout";

type PrivateRouteProps = React.PropsWithChildren<{}>

const PrivateRoute = ({children}:PrivateRouteProps) => {
    const dispatch = useAppDispatch();
    let location = useLocation();
    const navigate = useNavigate();
    const { isAuth } = useAppSelector(state => state.user);
    // console.log('isAuth',isAuth);
	let from = location.state?.from?.pathname || location || "/";
	useEffect(() => {
		const verifyUserToken = async (userToken:string) => {
			if(userToken === ''){
				dispatch(loginReset());
			}else{
				const result = await dispatch(userTokenAuthAction(userToken));
				if(result?.status === 'Error'){
					dispatch(loginReset());
				}else{
					dispatch(loginSuccess({userToken:result?.data?.userToken ?? '',userData:result?.data?.userData ?? {}}));
					navigate(from, { replace: true });
				}
			}
		};
		// If in session Storage userToken is Already Present then verify the userToken
		!isAuth && localStorage.getItem("userToken") && verifyUserToken(localStorage.getItem("userToken") ?? '');

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

    return (
		isAuth ? (
			<DefaultLayout>{children}</DefaultLayout>
		) : (
			<Navigate
				to="/login"
				state={{from:location}}
			/>
		)
	);
}

export default PrivateRoute



/*
export const PrivateRoute = () => {
	
	
	let from = location.state?.from?.pathname || location || "/";
	useEffect(() => {
		const verifyUserToken = async (userToken) => {
			const result = await dispatch(userTokenAuthenticateAction(userToken));
			if(result.status === 'Error'){
				dispatch(loginReset())
			}else{
				dispatch(loginSuccess({userToken:result.data.userToken,userData:result.data.user}));
				navigate(from, { replace: true });
			}
		};
		// If in session Storage userToken is Already Present then verify the userToken
		!isAuth && localStorage.getItem("userToken") && verifyUserToken(localStorage.getItem("userToken"));

		//!isAuth && localStorage.getItem("userToken") && dispatch(loginSuccess());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		isAuth ? (
			<DefaultLayout>{children}</DefaultLayout>
		) : (
			<Navigate
				to={{
					pathname: "/login",
					state: { from: location },
				}}
			/>
		)
	);
};*/