import { appTokenPending, appTokenSuccess, appTokenFail } from "../slice/appSlice";
import { appAuth, appTokenAuth } from "../../service/apiCalls/appAuthApiCalls";
import { apiConfig } from "../../configs/apiConfig";
import { AppDispatch } from "../store";
import {AxiosError} from "axios";

export const appAuthAction = () => async (dispatch:AppDispatch) => {
    try{
        dispatch(appTokenPending());
        const result = await appAuth(apiConfig,'');
        if(result.status === 'Ok'){
            dispatch(appTokenSuccess({appToken:result.data.token}));
        }else{
            dispatch(appTokenFail(result.errorMessage));
        }
        return result;
    }catch(error){
        const err = error as AxiosError
        dispatch(appTokenFail(err.message ?? 'Token API Failed'));
    }
}

export const appTokenAuthAction = (appToken:string) => async (dispatch:AppDispatch) => {
    try{
        dispatch(appTokenPending());
        const result = await appTokenAuth({},appToken);
        if(result.status === 'Ok'){
            dispatch(appTokenSuccess({appToken:result.data.token}));
        }else{
            dispatch(appTokenFail(result.errorMessage));
        }
        return result;
    }catch(error){
        const err = error as AxiosError
        dispatch(appTokenFail(err.message ?? 'Token API Failed'));
        return {status:'Error', message:err.message ?? 'Token API Failed'};
    }
}