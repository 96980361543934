import { useSelector } from "react-redux";
import {
  createStyles,
  Avatar,
  Box,
  Group,
  Loader,
  Text,
  Menu,
  rem,
  UnstyledButton,
} from "@mantine/core";
import { RootState } from "../../../redux/store";
import { useAppDispatch } from "../../../redux/hooks";
import { getUserModuleAccessAction } from "../../../redux/actions/userAuthActions";
import { setSelectedModule } from '../../../redux/slice/userSlice';

import {
  IconChevronDown,
  IconFileSettings,
} from "@tabler/icons-react"
import { useEffect } from "react"

const useStyles = createStyles((theme) => ({
  moduleContainer: {
    padding: "0rem",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.fn.variant({
      variant: "light",
      color: theme.primaryColor,
    }).background,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[8]
          : theme.primaryColor,
    },
  },
  icon: {
    color: theme.fn.variant({
      variant: "dark",
      color: theme.primaryColor,
    }).background,
    backgroundColor: theme.fn.variant({
      variant: "light",
      color: theme.primaryColor,
    }).background,
  },
  module: {
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    transition: "background-color 100ms ease",

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[8]
          : theme.primaryColor,
    },
  },

  moduleDropDown: {},

  menuDropdown: {
    [theme.fn.smallerThan("xs")]: {
      width: `${rem(380)} !important`,
      marginLeft: "1rem",
      marginRight: "2rem",
      left: "0 !Important",
      border: "1px solid red",
    },
    borderColor: theme.fn.variant({
      variant: "filled",
      color: theme.primaryColor,
    }).background,
  },
  moduleListActive: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.primaryColor,
  },
}))

interface UserAllowedModuleProps {
  isModuleOpened: boolean
  setIsModuleOpened: React.Dispatch<React.SetStateAction<boolean>>
}

const UserAllowedModule: React.FC<UserAllowedModuleProps> = ({
  isModuleOpened,
  setIsModuleOpened,
}) => {
  const { classes, theme, cx } = useStyles();
  const {
    isAuth,
    userToken,
    isModuleLoading,
    isModuleLoaded,
    userAllowedModules,
    selectedModule,
    moduleAllowedError,
  } = useSelector((state: RootState) => state.user);
  const dispatch = useAppDispatch();
  useEffect(() => {
    const getUserAllowedModules = async () => {
      if (isAuth) await dispatch(getUserModuleAccessAction(userToken))
    }
    if(!isModuleLoaded && !isModuleLoading)
        getUserAllowedModules();

  }, [])
  return (
    <Box className={classes.moduleContainer}>
      {moduleAllowedError !== "" && <Text c="#a22e10">Module Error...</Text>}
      {isModuleLoading ? (
        <Loader />
      ) : (
        <Menu
          shadow="md"
          width={300}
          position="bottom"
          transitionProps={{ transition: "pop-top-right" }}
          onClose={() => setIsModuleOpened(false)}
          onOpen={() => setIsModuleOpened(true)}
          withinPortal
        >
          <Menu.Target>
            <UnstyledButton
              className={classes.module}
            >
              <Group spacing={7}>
                <Avatar
                  alt={selectedModule?.moduleName ?? "Guest"}
                  radius="xl"
                  size={32}
                >
                  {" "}
                  <IconFileSettings size={32} className={classes.icon} />
                </Avatar>
                <Text weight={500} size="md" sx={{ lineHeight: 1 }} mr={3}>
                  {selectedModule?.moduleName ?? "Guest"}
                </Text>
                <IconChevronDown size={rem(24)} stroke={1.5} />
              </Group>
            </UnstyledButton>
          </Menu.Target>
          <Menu.Dropdown className={classes.menuDropdown}>
            <Menu.Label>Select The Module</Menu.Label>
            {isModuleLoaded && userAllowedModules.map((module,i) => {
                return <Menu.Item key={i} color={selectedModule?.moduleId === module.moduleId ? theme.colors[theme.primaryColor][6] : ''} onClick={()=>{dispatch(setSelectedModule({selectedModule:module}))}}>{module.moduleName}</Menu.Item>
            })}
            <Menu.Divider />
          </Menu.Dropdown>
        </Menu>
      )}
    </Box>
  )
}

export default UserAllowedModule;