import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  createStyles,
  Avatar,
  Button,
  UnstyledButton,
  Group,
  Text,
  Menu,
  Modal,
  rem,
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { RootState } from "../../../redux/store";
import { useAppDispatch } from "../../../redux/hooks";
import { userLogoutAction } from "../../../redux/actions/userAuthActions";
import {
    IconLogout,
    IconSwitchHorizontal,
    IconChevronDown,
    IconUserCircle
  } from '@tabler/icons-react';

const useStyles = createStyles((theme) => ({
  user: {
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    transition: "background-color 100ms ease",

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white,
    },
  },

  userActive: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white,
  },
}))
const UserDetails = () => {
  const { classes, theme, cx } = useStyles();
  const [opened, { open, close }] = useDisclosure(false);
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  const {isLoading, userToken, userData} = useSelector((state:RootState) => state.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const matches = useMediaQuery('(min-width: 56.25em)');
  const handleLogout = async () => {
    const logoutResponse = await dispatch(userLogoutAction(userToken));
    if (logoutResponse?.status === 'Ok' && logoutResponse?.responseStatus === 'Ok') {
      notifications.show({
        message:"Logged Out Successfully",
        autoClose:5000
      });
      localStorage.removeItem('userToken');
      navigate('/login', { replace: true });
    }else{
      notifications.show({
        title:"Login Failed",
        message:logoutResponse?.message ?? "Logout Failed",
        autoClose:5000
      });
    }
  }
  // console.log('matches',matches);
  return (
    <>
    <Menu
      width={390}
      position={"bottom-end"}
      transitionProps={{ transition: "pop-top-right" }}
      onClose={() => setUserMenuOpened(false)}
      onOpen={() => setUserMenuOpened(true)}
      withinPortal
      withArrow arrowPosition="center"
    >
      <Menu.Target>
        <UnstyledButton
          className={cx(classes.user, { [classes.userActive]: userMenuOpened })}
        >
          <Group spacing={7}>
            <Avatar alt={userData?.userFullName ?? 'Guest'} radius="xl" size={32}> <IconUserCircle size={32} /></Avatar>
            <Text weight={500} size="md" sx={{ lineHeight: 1 }} mr={3}>
              {userData?.userFullName ?? 'Guest'}
            </Text>
            <IconChevronDown size={rem(24)} stroke={1.5} />
          </Group>
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>Settings</Menu.Label>
        <Menu.Item icon={<IconSwitchHorizontal size="2rem" stroke={1.5} />}>
          Change Password
        </Menu.Item>
        <Menu.Item icon={<IconLogout size="2rem" stroke={1.5} />} onClick={open}>
          Logout
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
    <Modal opened={opened} onClose={close} title="Logout Confirmation" centered keepMounted={false}>
      <Text fz="xl" fw={500} m="md">Are your sure want to logout?</Text>
      <Group position="right">
      <Button onClick={handleLogout} color={theme.colors[theme.primaryColor][6]} {...(isLoading ? {'loading':true} : {})} >
        Yes
      </Button>
      <Button onClick={close} variant="outline" color={theme.colors[theme.primaryColor][6]}>
        No
      </Button>
      </Group>
    </Modal>
    </>
  )
}

export default UserDetails
