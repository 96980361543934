import React, { useState } from "react"
import { useForm, isNotEmpty, isInRange } from "@mantine/form"
import {
  Box,
  Button,
  Divider,
  Flex,
  FocusTrap,
  Group,
  Modal,
  NumberInput,
  Radio,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core"
import { DatePickerInput, DateTimePicker } from "@mantine/dates"
import dayjs from "dayjs"
import {
  failedNotification,
  successNotification,
  // warningNotification,
} from "../../../../../features/common/notification"
import { useUpdateFuelVoucherData } from "../../../../../hooks/reactQuery/modules/pla/transactions/fuelVoucher"
import {
  IFuelVoucher,
  IFuelVoucherForm,
} from "../../../../../typesDefs/interfaces/modules/pla/transactions/fuelVoucher"
import { useFormStyles } from "../../../../../styles/formStyles"
import SiteDropDown from "../../../../../components/shared/common/DropDowns/masters/SiteDropDown"
import VehicleDropDown from "../../../../../components/shared/common/DropDowns/masters/VehicleDropDown"

interface FuelVoucherFormProps {
  opened: boolean
  close: () => void
  editData: IFuelVoucher | null
}

const FuelVoucherForm: React.FC<FuelVoucherFormProps> = ({
  opened,
  close,
  editData,
}) => {
  const { classes, theme } = useFormStyles()
  const [isEditMode] = useState(editData ? true : false)
  const form = useForm({
    initialValues: {
      fuelVoucherId: editData?.fuelVoucherId ?? "",
      trType: editData?.trType?.toString() ?? "1",
      vehicleId: editData?.vehicleId ?? "",
      atSiteId: editData?.atSiteId ?? "",
      transactionDate: editData?.transactionDate
        ? dayjs(editData?.transactionDate)
        : new Date(),
      qty: editData?.qty ?? 0,
      kmReading: editData?.kmReading ?? 0,
      hrReading: editData?.hrReading ?? 0,
      kmAverageApp: editData?.kmAverageApp ?? "N",
      hrAverageApp: editData?.hrAverageApp ?? "N",
      transactionBy: editData?.transactionBy ?? "",
      remark: editData?.remark ?? "",
    },
    validate: {
      transactionDate: isNotEmpty("Transit Date Cannot Blank"),
      vehicleId: isNotEmpty("Select Vehicle"),
      qty: isInRange({ min: 0.0001 }, "Qty Should Be Greater Than 0"),
      kmReading: (value, values) =>
        values.kmAverageApp === "Y" && value <= 0
          ? "KM Reading Required"
          : null,
      hrReading: (value, values) =>
        values.hrAverageApp === "Y" && value <= 0
          ? "Hour Reading Required"
          : null,
      transactionBy: isNotEmpty("Enter Transit By"),
    },
    transformValues: (values) => ({
      ...values,
      trType: Number(values.trType),
      transactionDate: dayjs(values.transactionDate).format("YYYY-MM-DD"),
    }),
  })
  const {
    isPending,
    isSuccess,
    isError,
    error,
    mutateAsync: saveFuelVoucher,
  } = useUpdateFuelVoucherData()

  const formValidationHandler = async (values: IFuelVoucherForm) => {
    try {
      const response = await saveFuelVoucher(values, {
        onSuccess: (response, variables, context) => {
          if (response.status === "Ok") {
            successNotification({
              title: "Fuel Voucher - Successfully",
              message: `Fuel Voucher ${
                isEditMode ? "Updated" : "Added"
              } Successfully`,
            })
            form.reset()
            if (isEditMode) {
              close()
            }
          } else {
            failedNotification({
              title: "Fuel Voucher - Failed",
              message:
                response.errorMessage ??
                `Fuel Voucher ${isEditMode ? "Update" : "Add"} Failed `,
            })
          }
        },
        onError: (error, variables, context) => {
          failedNotification({
            title: "Fuel Voucher - Failed",
            message:
              error?.message ?? `Site ${isEditMode ? "Update" : "Add"} Failed`,
          })
        },
      })
    } catch (error) {
      failedNotification({
        title: "Fuel Voucher - Failed",
        message:
          error?.message ?? `Site ${isEditMode ? "Update" : "Add"} Failed`,
      })
    }
  }
  return (
    <Modal
      opened={opened}
      onClose={close}
      title={
        <Text fz="md" fw="600" c={theme.colors[theme.primaryColor][6]}>
          Fuel Voucher
        </Text>
      }
      centered
      size="64rem"
      closeOnClickOutside={false}
      keepMounted={false}
    >
      <Divider my="sm" />
      {isError && (
        <Box className={classes.formError}>
          <Text c="red.7" fz="md" fw={600} ta="center">
            {error.message}
          </Text>
        </Box>
      )}
      <FocusTrap active={true}>
        <form
          onSubmit={form.onSubmit((values) => formValidationHandler(values))}
        >
          <Flex
            gap="xl"
            justify="flex-start"
            align="flex-start"
            direction="row"
            wrap="wrap"
            sx={(theme) => ({
              // flexDirection:'row',
              "@media (max-width: 40em)": {
                flexDirection: "column",
                flex: 1,
              },
            })}
            // columnGap="xl"
          >
            <Box
              sx={(theme) => ({
                width: "47%",
                "@media (max-width: 40em)": {
                  width: "100%",
                },
              })}
            >
              <Flex
                justify="flex-start"
                align="flex-start"
                direction="column"
                wrap="wrap"
                sx={(theme) => ({ width: "100%" })}
              >
                <Radio.Group
                  {...form.getInputProps("trType")}
                  name="status"
                  label="Select Status"
                  withAsterisk
                  mt="sm"
                >
                  <Group mt="xs">
                    <Radio value="1" label="Fuel Issued" />
                    <Radio value="2" label="Fuel Received" />
                  </Group>
                </Radio.Group>
                <DatePickerInput
                  clearable
                  valueFormat="DD-MM-YYYY"
                  label="Transit Date"
                  placeholder="Transit Date"
                  mx="auto"
                  w="100%"
                  className={classes.input}
                  maxDate={new Date()}
                  {...form.getInputProps("transactionDate")}
                />
                <VehicleDropDown
                  {...form.getInputProps("vehicleId")}
                  onChangeCallback={(selectedItem) => {
                    if (selectedItem) {
                      form.setFieldValue(
                        "atSiteId",
                        selectedItem.currentSiteId ?? "",
                      )
                      form.setFieldValue(
                        "kmAverageApp",
                        selectedItem.kmAverageApp ?? "N",
                      )
                      form.setFieldValue(
                        "hrAverageApp",
                        selectedItem.hrAverageApp ?? "N",
                      )
                      if(selectedItem.kmAverageApp === 'N'){
                        form.setFieldValue("kmReading", 0)
                      }
                      if(selectedItem.hrAverageApp === 'N'){
                        form.setFieldValue("hrReading", 0)
                      }
                    } else {
                      form.setFieldValue("atSiteId", "")
                      form.setFieldValue("kmAverageApp", "N")
                      form.setFieldValue("hrAverageApp", "N")
                      form.setFieldValue("kmReading", 0)
                      form.setFieldValue("hrReading", 0)
                    }
                  }}
                  data={[]}
                />
                <SiteDropDown
                  {...form.getInputProps("atSiteId")}
                  label="At Site"
                  placeHolder="At Site"
                  isDisabled={true}
                />
                <NumberInput
                  data-autofocus
                  label="Qty"
                  placeholder="Qty"
                  withAsterisk
                  className={classes.input}
                  size="sm"
                  type="number"
                  w="100%"
                  hideControls
                  precision={3}
                  {...form.getInputProps("qty")}
                />
              </Flex>
            </Box>
            <Box
              sx={(theme) => ({
                width: "47%",
                marginRight: "0.5rem",
                "@media (max-width: 40em)": {
                  width: "100%",
                },
              })}
            >
              {form.values.kmAverageApp === "Y" && (
                <NumberInput
                  data-autofocus
                  label="KM Reading"
                  placeholder="KM Reading"
                  withAsterisk
                  className={classes.input}
                  size="sm"
                  type="number"
                  w="100%"
                  hideControls
                  precision={1}
                  {...form.getInputProps("kmReading")}
                />
              )}
              {form.values.hrAverageApp === "Y" && (
                <NumberInput
                  data-autofocus
                  label="Hour Reading"
                  placeholder="Hour Reading"
                  withAsterisk
                  className={classes.input}
                  size="sm"
                  type="number"
                  w="100%"
                  hideControls
                  precision={1}
                  {...form.getInputProps("hrReading")}
                />
              )}
              <TextInput
                label={`${
                  form.values.trType === "1" ? "Issued By" : "Received By"
                }`}
                placeholder={`${
                  form.values.trType === "1" ? "Issued By" : "Received By"
                }`}
                withAsterisk
                className={classes.input}
                size="sm"
                w="100%"
                {...form.getInputProps("transactionBy")}
              />
              <Textarea
                label="Remark"
                placeholder="Remark"
                autosize
                minRows={2}
                maxRows={4}
                {...form.getInputProps("remark")}
                w="100%"
              />
            </Box>
          </Flex>

          <Divider my="sm" />
          <Group position="right" mt="lg">
            <Button
              variant="outline"
              onClick={() => {
                form.reset()
                close()
              }}
            >
              Cancel
            </Button>
            <Button variant="outline" onClick={() => form.reset()}>
              Reset
            </Button>
            <Button type="submit" loading={isPending}>
              Save
            </Button>
          </Group>
        </form>
      </FocusTrap>
    </Modal>
  )
}

export default FuelVoucherForm
