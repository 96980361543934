import { useSelector } from "react-redux";
import {
  createStyles,
  Burger,
  Container,
  Group,
  Header,
  Image,
  rem,
} from "@mantine/core"

import UserDetails from "./UserDetails"
import UserAllowedCompanies from "./UserAllowedCompanyDetails";
import { RootState } from "../../../redux/store";
import { useAppDispatch } from "../../../redux/hooks";
import { toggleNavbar } from "../../../redux/slice/appSlice";

const useStyles = createStyles((theme) => ({
  header: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
    borderBottom: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[2]
    }`,
    width:'100%'
  },

  mainSection: {
    paddingBottom: theme.spacing.sm,
    maxWidth:'100%'
  },

  companyUserSection : {
    [`@media (max-width: 51.2em)`]: {
      display: "none",
    },
  }
}));

interface PageHeaderProps {
  // opened:boolean,
  // setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const PageHeader: React.FC<PageHeaderProps> = () => {
  const { classes, theme, cx } = useStyles();
  const {isNavbarOpen} = useSelector((state:RootState) => state.app);
  const dispatch = useAppDispatch();
  return (
    <div className={classes.header}>
      <Header height={80} mb="sm">
      <Container className={classes.mainSection}>
          <Group position="apart">
            <div
              style={{ display: "flex", alignItems: "center", height: "100%" }}
            >
              <Image
                src={"/assets/images/shivamerp-logo.png"}
                fit="contain"
                width={160}
                height={65}
              />
              <Burger
                opened={isNavbarOpen}
                onClick={() => dispatch(toggleNavbar())}
                size="md"
                color={theme.colors.gray[6]}
                mr="xl"
                ml="xl"
              />
            </div>
            <Group spacing="sm" className={classes.companyUserSection}>
              <UserAllowedCompanies />
              <UserDetails />
            </Group>
          </Group>
        
      </Container>
      </Header>
    </div>
  )
}

export default PageHeader
