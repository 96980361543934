import { useMemo } from "react"
import { useSelector } from "react-redux"
import {
  useMutation,
  useQuery,
  useQueryClient,
  keepPreviousData,
} from "@tanstack/react-query"
import {
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
} from "mantine-react-table"
import dayjs from "dayjs"
import {
  getFuelVouchersList,
  addFuelVoucher,
  updateFuelVoucher,
  deleteFuelVoucher,
} from "../../../../../service/apiCalls/plaApiCalls"

import {
  IFuelVoucher,
  IFuelVoucherPayload,
  IFuelVoucherForm,
} from "../../../../../typesDefs/interfaces/modules/pla/transactions/fuelVoucher"
import { RootState } from "../../../../../redux/store"

interface Params {
  columnFilters: MRT_ColumnFiltersState
  sorting: MRT_SortingState
  pagination: MRT_PaginationState
  payload: IFuelVoucherPayload
}

export const useFuelVoucherColumns = () => {
  return useMemo<MRT_ColumnDef<IFuelVoucher>[]>(
    () => [
      {
        accessorKey: "vehicleNo",
        header: "Vehicle No",
        size: 200,
        mantineTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "vehicleTypeTitle",
        header: "Vehicle Type Title",
        size: 250,
      },
      {
        accessorKey: "transactionDate",
        header: "Txn Date",
        size: 175,
        mantineTableBodyCellProps: {
          align: "center",
        },
        accessorFn: (row) => dayjs(row.transactionDate).format("DD-MM-YYYY"),
        filterVariant: "date-range",
        mantineFilterDateInputProps: {
          // clearable:true,
          valueFormat: "DD-MM-YYYY",
        },
      },
      {
        accessorKey: "atSiteName",
        header: "At Site Name",
        size: 250,
      },
      {
        accessorKey: "qty",
        header: "Qty",
        size: 150,
      },
      {
        accessorKey: "kmReading",
        header: "KM Reading",
        size: 150,
      },
      {
        accessorKey: "hrReading",
        header: "HR Reading",
        size: 150,
      },
      {
        accessorKey: "transactionBy",
        header: "Transaction By",
        size: 250,
      },
      {
        accessorKey: "entryByName",
        header: "Entry By",
        mantineTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "entryTime",
        header: "Entry Time",
        size: 150,
        accessorFn: (row) => dayjs(row.entryTime).format("DD-MM-YYYY hh:mm"),
        mantineTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "updateByName",
        header: "Update By",
        mantineTableHeadCellProps: {
          align: "center",
        },
        mantineTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "updateTime",
        header: "Update Time",
        size: 150,
        accessorFn: (row) => dayjs(row.updateTime).format("DD-MM-YYYY hh:mm"),
        mantineTableBodyCellProps: {
          align: "center",
        },
      },
    ],
    [],
  )
}

// Custom Hooks For React Query
//READ hook (get Fuel Voucher List from api)
export const useGetFuelVouchers = ({
  columnFilters,
  sorting,
  pagination,
  payload,
}: Params) => {
  const { userToken } = useSelector((state: RootState) => state.user)
  let columnFilterData = {}
  if (columnFilters && columnFilters.length > 0) {
    columnFilters.forEach((filter) => {
      if (Array.isArray(filter.value)) {
        filter.value.forEach((filterValue, index) => {
          if (filterValue instanceof Date) {
            const key = `${index === 0 ? "from" : "to"}${filter.id}`
            columnFilterData = {
              ...columnFilterData,
              [key]: dayjs(filterValue).format("YYYY-MM-DD"),
            }
          } else {
            columnFilterData = { ...columnFilterData, [filter.id]: filterValue }
          }
        })
      } else {
        columnFilterData = { ...columnFilterData, [filter.id]: filter.value }
      }
    })
  }
  const requestPayload = {
    ...payload,
    pageNo: pagination.pageIndex + 1,
    perPageRecords: pagination.pageSize,
    sortBy: sorting?.[0]?.id ?? "",
    sortOrder: sorting?.[0]?.desc ? "DESC" : "ASC",
    ...columnFilterData,
  }
  return useQuery({
    queryKey: ["plaFuelVouchersList", requestPayload],
    placeholderData: keepPreviousData,
    staleTime: 30000,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      //send api request here
      const result =
        (await getFuelVouchersList(requestPayload, userToken)) ?? []
      if (result.status === "Ok") return result.data
      else throw new Error(result.errorMessage ?? "API Call Failed")
    },
  })
}

const updateFuelVoucherHelper = async (
  fuelVoucherData: IFuelVoucherForm,
  userToken: string,
) => {
  let result
  if (fuelVoucherData?.fuelVoucherId === "") {
    result = await addFuelVoucher(fuelVoucherData, userToken)
  } else {
    result = await updateFuelVoucher(fuelVoucherData, userToken)
  }
  return result
}

const deleteFuelVoucherHelper = async (
  fuelVoucherId: string,
  userToken: string,
) => {
  const result = await deleteFuelVoucher({ fuelVoucherId }, userToken)
  return result
}

// Update Hook
export const useUpdateFuelVoucherData = () => {
  const { userToken } = useSelector((state: RootState) => state.user)

  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (fuelVoucherData: IFuelVoucherForm) =>
      updateFuelVoucherHelper(fuelVoucherData, userToken),
    onSuccess: (response, variables, context) => {
      if (response.status === "Ok") {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ["plaFuelVouchersList"] })
      }
    },
    onError: (error) => {},
  })
}

// Delete Hook
export const useDeleteFuelVoucherData = () => {
  const { userToken } = useSelector((state: RootState) => state.user)

  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (fuelVoucherId: string) =>
      deleteFuelVoucherHelper(fuelVoucherId, userToken),
    onSuccess: (response, variables, context) => {
      if (response.status === "Ok") {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ["plaFuelVouchersList"] })
      }
    },
    onError: (error) => {},
  })
}
