import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./slice/appSlice";
import userReducer from "./slice/userSlice";
// Ocgc
// Masters
import packingTypeMasterReducer  from './slice/modules/ocgc/masters/packingType';
// Transactions

// PLA
// Masters
import plaSiteMasterReducer from './slice/modules/pla/masters/site';

export const store = configureStore({
    reducer:{
        app:appReducer,
        user:userReducer,
        packingTypeMaster:packingTypeMasterReducer,
        plaSiteMaster:plaSiteMasterReducer,
    }
})
//Infer the RootState and AppDispatch types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type {login: LoginState}
export type AppDispatch = typeof store.dispatch