import React, { forwardRef, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import {
  Box,
  Grid,
  Group,
  Loader,
  MantineColor,
  Select,
  SelectProps,
  SelectItemProps,
  Text,
  ThemeIcon,
} from "@mantine/core"
import { IconRefresh } from "@tabler/icons-react"
import { useFormStyles } from "../../../../../styles/formStyles"
import { backendApiCall } from "../../../../../service/apiCalls/commonApiCalls"
import { IVehicleAutocomplete } from "../../../../../typesDefs/interfaces/modules/pla/masters/vehicles"
import { RootState } from "../../../../../redux/store"

interface ItemProps extends SelectItemProps {
  color: MantineColor
  value: string
  label: string
  currentSiteId: string
  currentSiteName: string
  kmAverageApp: string
  hrAverageApp: string
}

const AutoCompleteItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others} style={{ overflow: "auto" }}>
      <Box sx={{ width: "30rem", flexWrap: "nowrap" }}>
        <Grid>
          <Grid.Col span={6}>
            <Text>{label}</Text>
          </Grid.Col>
        </Grid>
      </Box>
    </div>
  ),
)

interface AutocompletePayload {
  getFullDetails: string
  status: string
  pageNo: number
  perPageRecords: number
  vehicleNo?: string
}

interface AutocompleteProps extends SelectProps {
  label?: string
  placeHolder?: string
  isDisabled?: boolean
  onChangeCallback?: (
    selectedValue: IVehicleAutocomplete | null | undefined,
  ) => void
}

const VehicleDropDown: React.FC<AutocompleteProps> = (props) => {
  const {
    label = "Select Vehicle",
    placeHolder = "Select Vehicle",
    isDisabled = false,
    onChangeCallback,
    onChange: defaultOnChange,
    ...others
  } = props
  const { userToken } = useSelector((state: RootState) => state.user)
  const { classes, theme } = useFormStyles()
  const [isRemoteData, setIsRemoteData] = useState(false)
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [vehiclesData, setVehiclesData] = useState({
    totalRecords: 0,
    vehicles: [],
  })

  const getVehicleData = async (
    payload: AutocompletePayload = {
      getFullDetails: "N",
      status: "A",
      pageNo: 1,
      perPageRecords: 1000,
      vehicleNo: "",
    },
  ) => {
    setIsDataLoading(true)
    const result =
      (await backendApiCall(
        "pla/getVehiclesForAutocomplete",
        payload,
        userToken,
      )) ?? []
    setIsDataLoading(false)
    if (result.status === "Ok") {
      setVehiclesData(result?.data)
      if (result?.data?.totalRecords && result?.data?.totalRecords > 1000) {
        setIsRemoteData(true)
      }
    } else throw new Error(result.errorMessage ?? "API Call Failed")
  }

  const reloadData = async () => {
    await getVehicleData({
      getFullDetails: "N",
      status: "A",
      pageNo: 1,
      perPageRecords: 1000,
    })
  }

  const handleSearchChange = async (value: string) => {
    if (isRemoteData) {
      await getVehicleData({
        getFullDetails: "N",
        status: "A",
        pageNo: 1,
        perPageRecords: 1000,
        vehicleNo: value,
      })
    }
  }

  const handleChange = async (value: string) => {
    if (defaultOnChange) defaultOnChange(value)
    if (onChangeCallback && typeof onChangeCallback === "function") {
      const selectedVehicle = vehiclesData.vehicles.find(
        (vehicle) => vehicle["value"] === value,
      )
      // if(selectedItem)
      onChangeCallback(selectedVehicle)
    }
  }

  useEffect(() => {
    getVehicleData()
  }, [])

  return (
    <Box
      sx={(theme) => ({
        width: "100%",
        alignItems: "flex-end",
        paddingBottom: "0.5rem",
      })}
    >
      <Group sx={(theme) => ({ width: "100%", alignItems: "flex-end" })}>
        <Select
          className={classes.input}
          label={label}
          placeholder={placeHolder}
          searchable
          clearable
          itemComponent={AutoCompleteItem}
          disabled={isDisabled}
          w="85%"
          rightSection={isDataLoading ? <Loader size="1rem" /> : null}
          filter={(value, item) =>
            item?.label?.toLowerCase().includes(value.toLowerCase().trim()) ??
            false
          }
          {...{
            ...others,
            onChange: handleChange,
            onSearchChange: handleSearchChange,
            data: vehiclesData?.vehicles ?? [],
          }}
          withinPortal={true}
        />
        <ThemeIcon variant="outline" bottom="0">
          <IconRefresh onClick={reloadData} />
        </ThemeIcon>
      </Group>
    </Box>
  )
}

export default VehicleDropDown
