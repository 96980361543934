import { postApiCall, apiResponseType } from "./apiService";

// Masters
// API Calls For Site Master
export const getSiteMasterList = async (payload = {},userToken = ''):Promise<apiResponseType> => {
    return await postApiCall('pla/getSites',payload,userToken);
}
export const addSiteMaster = async (payload = {},userToken = ''):Promise<apiResponseType> => {
    return await postApiCall('pla/addSite',payload,userToken);
}
export const updateSiteMaster = async (payload = {},userToken = ''):Promise<apiResponseType> => {
    return await postApiCall('pla/updateSite',payload,userToken);
}
export const deleteSiteMaster = async (payload = {},userToken = ''):Promise<apiResponseType> => {
    return await postApiCall('pla/deleteSite',payload,userToken);
}

// Transactions
// Goods Receipts
export const getGoodsReceiptsList = async (payload = {},userToken = ''):Promise<apiResponseType> => {
    return await postApiCall('pla/getGoodsReceipts',payload,userToken);
}
export const addGoodsReceipts = async (payload = {},userToken = ''):Promise<apiResponseType> => {
    let payloadData = payload;
    return await postApiCall('pla/addGoodsReceipt',  payloadData, userToken, true);
}
export const updateGoodsReceipts = async (payload = {},userToken = ''):Promise<apiResponseType> => {
    return await postApiCall('pla/updateGoodsReceipt',payload,userToken, true);
}
export const deleteGoodsReceipts = async (payload = {},userToken = ''):Promise<apiResponseType> => {
    return await postApiCall('pla/deleteGoodsReceipt',payload,userToken);
}

// Vehicle Transits
export const getVehicleTransitsList = async (payload = {},userToken = ''):Promise<apiResponseType> => {
    return await postApiCall('pla/getVehicleTransits',payload,userToken);
}
export const addVehicleTransit = async (payload = {},userToken = ''):Promise<apiResponseType> => {
    let payloadData = payload;
    return await postApiCall('pla/addVehicleTransit',  payloadData, userToken, true);
}
export const updateVehicleTransit = async (payload = {},userToken = ''):Promise<apiResponseType> => {
    return await postApiCall('pla/updateVehicleTransit',payload,userToken, true);
}
export const deleteVehicleTransit = async (payload = {},userToken = ''):Promise<apiResponseType> => {
    return await postApiCall('pla/deleteVehicleTransit',payload,userToken);
}

// Fuel Voucher
export const getFuelVouchersList = async (payload = {},userToken = ''):Promise<apiResponseType> => {
    return await postApiCall('pla/getFuelVouchers',payload,userToken);
}
export const addFuelVoucher = async (payload = {},userToken = ''):Promise<apiResponseType> => {
    let payloadData = payload;
    return await postApiCall('pla/addFuelVoucher',  payloadData, userToken, false);
}
export const updateFuelVoucher = async (payload = {},userToken = ''):Promise<apiResponseType> => {
    return await postApiCall('pla/updateFuelVoucher',payload,userToken, false);
}
export const deleteFuelVoucher = async (payload = {},userToken = ''):Promise<apiResponseType> => {
    return await postApiCall('pla/deleteFuelVoucher',payload,userToken);
}