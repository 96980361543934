import { useState } from "react"
import { useDisclosure } from "@mantine/hooks"
import { createStyles, Box, Flex, LoadingOverlay, Text } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import {
  MantineReactTable,
  useMantineReactTable,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_Row,
  type MRT_SortingState,
} from "mantine-react-table"
import {
  failedNotification,
  successNotification,
  warningNotification,
} from "../../../../../features/common/notification"
import ActionBar from "../../../../../components/shared/dataTable/ActionBar"
import ActionBarBottom from "../../../../../components/shared/dataTable/ActionBarBottom"
import ConfirmationModal from "../../../../../components/shared/modals/ConfirmationModal"
import { IFuelVoucher } from "../../../../../typesDefs/interfaces/modules/pla/transactions/fuelVoucher"
import {
    useFuelVoucherColumns,
    useGetFuelVouchers,
    useDeleteFuelVoucherData,
  } from "../../../../../hooks/reactQuery/modules/pla/transactions/fuelVoucher"
import FuelVoucherForm from "./FuelVoucherForm"
import dayjs from "dayjs"
const useStyles = createStyles((theme) => ({}))

const FuelVoucher = () => {
    const { classes, theme, cx } = useStyles()
    const isDesktop = useMediaQuery("(min-width: 48em)")
    const columns = useFuelVoucherColumns()
    //Manage MRT state that we want to pass to our API
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([])
    const [sorting, setSorting] = useState<MRT_SortingState>([])
    const [pagination, setPagination] = useState<MRT_PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    })
  
    const [editData, setEditData] = useState<IFuelVoucher | null>(null)
    const [opened, { open, close }] = useDisclosure(false)
    const [
      deleteConfirmation,
      { open: openDeleteConfirmation, close: closeDeleteConfirmation },
    ] = useDisclosure(false)
    const {
      data: fuelVouchersData,
      isError: isLoadingError,
      isFetching,
      isLoading,
      refetch,
      error,
    } = useGetFuelVouchers({
      columnFilters,
      pagination,
      sorting,
      payload: { getFullDetails: "Y", formattedDate: "N" },
    })
    const {
      isPending: isDeletePending,
      mutateAsync: processDeleteFuelVoucher,
    } = useDeleteFuelVoucherData()
  
    const handleRowDoubleClick = (
      event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
      row: MRT_Row<IFuelVoucher>,
    ) => {
      const handler = row.getToggleSelectedHandler()
      handler(event)
      setTimeout(() => {
        editFuelVoucher()
      }, 100)
    }
    const table = useMantineReactTable({
      columns,
      data: fuelVouchersData?.fuelVouchers ?? [],
      enableStickyHeader: true,
      layoutMode: "grid",
      mantineTableContainerProps: {
        sx: { height: isDesktop ? "72vh" : "55vh", width: "89vw" },
      },
      enableColumnFilterModes: true,
      columnFilterModeOptions: ["contains"],
      initialState: { showColumnFilters: false },
      manualFiltering: true,
      manualPagination: true,
      manualSorting: true,
      enableMultiRowSelection: false,
      enableRowSelection: true,
      enableRowNumbers: true,
      selectDisplayMode: "switch",
      positionToolbarAlertBanner: "bottom",
      renderTopToolbarCustomActions: ({ table }) => (
        <Text fz="lg" fw={700} c={theme.colors[theme.primaryColor][6]} pl="md">
          Fuel Vouchers
        </Text>
      ),
      mantineToolbarAlertBannerProps: isLoadingError
        ? {
            color: "red",
            children: (
              <Text fz="md">{error?.message ?? "Error loading data"}</Text>
            ),
          }
        : undefined,
      onColumnFiltersChange: setColumnFilters,
      onPaginationChange: setPagination,
      onSortingChange: setSorting,
      rowCount: fuelVouchersData?.totalRecords ?? 0,
      columnFilterDisplayMode: "popover",
      state: {
        columnFilters,
        isLoading,
        pagination,
        sorting,
        showAlertBanner: isLoadingError,
        density: "xs",
      },
      mantineTableBodyRowProps: ({ row }) => ({
        onDoubleClick: (event) => {
          handleRowDoubleClick(event, row)
        },
        onClick: row.getToggleSelectedHandler(),
        sx: {
          cursor: "pointer", //you might want to change the cursor too when adding an onClick
        },
      }),
    })
  
    const addFuelVoucher = () => {
      setEditData(null)
      open()
    }
    const editFuelVoucher = () => {
      const selectedRow = table.getSelectedRowModel().rows
      if (selectedRow.length === 0) {
        warningNotification({
          title: "Fuel Voucher - Selection",
          message: `Please Select The Record For Edit`,
        })
        return
      }
      setEditData(selectedRow[0].original)
      open()
    }
    const deleteFuelVoucher = () => {
      const selectedRow = table.getSelectedRowModel().rows
      if (selectedRow.length === 0) {
        warningNotification({
          title: "Fuel Voucher - Selection",
          message: `Please Select The Record For Delete`,
        })
        return
      }
      openDeleteConfirmation()
    }
  
    const actionButtonData = [
      { code: 0, label: "Reload Fuel Voucher Data", onClick: refetch },
      { code: 1, label: "Add Fuel Voucher", onClick: addFuelVoucher },
      { code: 2, label: "Edit Fuel Voucher", onClick: editFuelVoucher },
      { code: 3, label: "Delete Fuel Voucher", onClick: deleteFuelVoucher },
    ]
  
    const getConfirmationProps = () => {
      return {
        title: "Delete Fuel Voucher",
        label: { confirm: "Delete", cancel: "Cancel" },
        size: "auto",
        opened: deleteConfirmation,
        onCancel: closeDeleteConfirmation,
        onConfirm: processRecordDelete,
        onClose: closeDeleteConfirmation,
      }
    }
  
    const processRecordDelete = async () => {
      try {
        const selectedRow = table.getSelectedRowModel()?.rows[0]
        const fuelVoucherId = selectedRow.original.fuelVoucherId
        if (fuelVoucherId) {
          closeDeleteConfirmation()
          await processDeleteFuelVoucher(fuelVoucherId, {
            onSuccess: (response, variables, context) => {
              if (response.status === "Ok") {
                successNotification({
                  title: "Fuel Voucher - Successfully",
                  message: `Fuel Voucher Deleted Successfully`,
                })
                // dispatch(resetSiteEditMode())
                table.resetRowSelection()
              } else {
                failedNotification({
                  title: "Fuel Voucher - Failed",
                  message:
                    response.errorMessage ?? `Fuel Voucher Delete Failed `,
                })
              }
            },
            onError: (error, variables, context) => {
              failedNotification({
                title: `Fuel Vouhcer - Failed`,
                message: error?.message ?? `Fuel Voucher Delete Failed`,
              })
            },
          })
        }
      } catch (error) {
        failedNotification({
          title: `Fuel Voucher - Failed`,
          message: error?.message ?? `Fuel Voucher Delete Failed`,
        })
      }
    }
  
    return (
      <Box pos="relative">
        <LoadingOverlay
          visible={isLoading || isFetching || isDeletePending}
          overlayBlur={2}
        />
        <Flex
          direction={isDesktop ? "row" : "column"}
          justify="flex-end"
          gap="md"
        >
          <MantineReactTable table={table} />
          {opened && (
            <FuelVoucherForm
              opened={opened}
              close={close}
              editData={editData}
            />
          )}
          {deleteConfirmation && (
            <ConfirmationModal {...getConfirmationProps()}>
              <Text>
                Are You Sure Want To Delete Fuel Voucher, For Vehicle :
                {table.getSelectedRowModel()?.rows[0]?.original
                  ? table.getSelectedRowModel()?.rows[0]?.original.vehicleNo
                  : ""}
                {", "}
                Of Date :{" "}
                {table.getSelectedRowModel()?.rows[0]?.original
                  ? dayjs(table.getSelectedRowModel()?.rows[0]?.original.transactionDate).format("DD-MM-YYYY")
                  : ""}{" "}
                ?
              </Text>
            </ConfirmationModal>
          )}
          {isDesktop ? (
            <ActionBar actionButtonData={actionButtonData} />
          ) : (
            <ActionBarBottom actionButtonData={actionButtonData} />
          )}
        </Flex>
      </Box>
    )
  }
  
  export default FuelVoucher