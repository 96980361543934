import { postApiCall } from "./apiService";

export const userAuth = async (loginDetails = {},appToken = '') => {
    return await postApiCall('common/userAuth',loginDetails,appToken);
}

export const userTokenAuth = async (tokenDetails = {},appToken = '') => {
    return await postApiCall('common/userTokenAuth',tokenDetails,appToken)
}

export const userLogout = async (tokenDetails = {},appToken = '') => {
    return await postApiCall('common/userLogout',tokenDetails,appToken)
}


export const getUserModuleAccess = async (userToken = '') => {
    return await postApiCall('common/getUserModuleAccess',{},userToken)
}

export const getUserCompanyAccess = async (userToken = '') => {
    return await postApiCall('common/getUserCompanyAccess',{},userToken)
}

export const getUserMenuAccess = async (userToken = '',payload = {}) => {
    return await postApiCall('common/getUserMenus',payload,userToken)
}

export const updateCompanySelection = async (userToken = '',payload = {}) => {
    return await postApiCall('common/updateCompanySelectionChange',payload,userToken)
}