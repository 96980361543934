import { notifications } from "@mantine/notifications"
import { IconExclamationMark, IconX, IconCheck } from "@tabler/icons-react"
import {
  notificationWarningProps,
  notificationSucessProps,
  notificationFailedProps,
} from "../../constants/notificationProps"

interface INotificationProps {
  title: string
  message: string
}

export const warningNotification = (warningProps: INotificationProps) => {
  notifications.show({
    ...warningProps,
    ...notificationWarningProps,
    icon: <IconExclamationMark />,
  })
}

export const successNotification = (successProps:INotificationProps) => {
  notifications.show({
    ...successProps,
    ...notificationSucessProps,
    icon: <IconCheck />,
  })
}

export const failedNotification = (failedProps:INotificationProps) => {
  notifications.show({
    ...failedProps,
    ...notificationFailedProps,
    icon: <IconX />,
  })
}
