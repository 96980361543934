import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  createStyles,
  rem,
  Avatar,
  Center,
  Container,
  Loader,
  Stack,
  Tooltip,
  UnstyledButton,
} from "@mantine/core"
import { menuActionData } from "./menuActionButtonData"
import { RootState } from "../../../redux/store"

interface NavbarLinkProps {
  icon: React.FC<any>
  label: string
  active?: boolean
  onClick?(): void
}

interface ActionBarProps {
    code:number,
    label:string,
    onClick?(): void
}

function NavbarLink({ icon: Icon, label, active, onClick }: NavbarLinkProps) {
  const { classes, theme, cx } = useStyles()
  return (
    <Tooltip label={label} position="left" transitionProps={{ duration: 0 }}>
      <UnstyledButton
        onClick={onClick}
        className={cx(classes.link, { [classes.active]: active })}
      >
        <Avatar color={theme.colors[theme.primaryColor][6]} radius="lg">
          <Icon size="1.5rem" c={theme.colors[theme.primaryColor][2]} />
        </Avatar>
      </UnstyledButton>
    </Tooltip>
  )
}

const useStyles = createStyles((theme) => ({
  actionButtonContainer:{
    width: "100px",
    float: "right",
    borderWidth: 1,
    borderStyle: "solid",
    marginLeft: "10px",
    marginRight: "10px",
    textAlign:'center',
    borderColor:theme.colors[theme.primaryColor][6]
    /* borderColor: theme.fn.variant({
      variant: "dark",
      color: theme.primaryColor,
    }).background, */
  },
  link: {
    width: rem(50),
    height: rem(50),
    borderRadius: theme.radius.md,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors.gray[0],
    },
  },

  active: {
    "&, &:hover": {
      backgroundColor: theme.fn.variant({
        variant: "light",
        color: theme.primaryColor,
      }).background,
      color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
        .color,
    },
  },
}))
const ActionBar = ({actionButtonData}: {actionButtonData: ActionBarProps[]}) => {
    const { classes, theme, cx } = useStyles()
    const { selectedMenu } = useSelector((state: RootState) => state.user);
    const [allowedActions, setAllowedActions] = useState<NavbarLinkProps[] | []>([]);
    const [isActionLoading, setIsActionLoading] = useState(true);
    useEffect(() => {
        const menuActions = selectedMenu?.actions.split(',') ?? [];
        if(menuActions.length > 0){
            menuActions.unshift('0');
            const intAllowedActions = menuActions.map((action) => parseInt(action));
            const filteredMenuActionButtons = menuActionData.filter((action) => intAllowedActions.indexOf(action.code) >= 0);
            const filteredActionButtons = filteredMenuActionButtons.filter((action) => actionButtonData.findIndex((ad) => ad.code === action.code) >= 0);
            const requiredActionButtons = filteredActionButtons.map((action) => {
                const eventActionObj = actionButtonData.find((ea) => ea.code === action.code);
                if(eventActionObj){
                    return {
                        ...action,
                        label:eventActionObj.label,
                        onClick:eventActionObj.onClick
                    }
                }
                return action;
            });
            setAllowedActions(requiredActionButtons);
            setIsActionLoading(false);
        }
    },[selectedMenu]);

    const actionButtons = isActionLoading ? <Center mt="lg"><Loader /></Center> : allowedActions.map((action) => {
        return (
            <NavbarLink key={action.label} icon={action.icon} label={action.label} active = {false} onClick={action.onClick ?? (()=>{})} />
        )
    });
  return (
    <Container
      className={classes.actionButtonContainer}
      ml="sx"
    >
      <Stack justify="center" spacing={0}>
        {actionButtons}
      </Stack>
    </Container>
  )
}

export default ActionBar
