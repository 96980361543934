import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useForm } from "@mantine/form"
import { createStyles, Button, Group, Select } from "@mantine/core"
import { notifications } from "@mantine/notifications"
import { IconX } from "@tabler/icons-react"
import { RootState } from "../../../redux/store"
import { useAppDispatch } from "../../../redux/hooks"
import { updateCompanySelectionChangeAction } from "../../../redux/actions/userAuthActions"

interface CompanyChangeFormProps {
  close: () => void
}

interface CompanySelectionForm {
  companyId: string
  financialYearId: string
  branchId: string
  isMultiBranch: string
}

interface companySelectInterface {
  value: string
  label: string
  disabled?: boolean
}
const useStyles = createStyles((theme) => ({
  errorMessage: {
    fontSize: "1rem",
    margin: "0.5rem",
    textAlign: "center",
    justifyContent: "center",
  },
}))
const CompanyChangeForm: React.FC<CompanyChangeFormProps> = ({ close }) => {
  const [financialYearData, setFinancialYearData] = useState<any[]>([])
  const [branchData, setBranchData] = useState<any[]>([])
  const {
    updateCompanyChangeLoading,
    userToken,
    userAllowedCompanies,
    selectedCompany,
  } = useSelector((state: RootState) => state.user)
  const { theme } = useStyles()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const form = useForm({
    initialValues: {
      companyId: selectedCompany?.company?.companyId ?? "",
      financialYearId: selectedCompany?.selectedFinancialYear?.fyId ?? "",
      branchId: selectedCompany?.selectedBranch?.branchId ?? "",
      isMultiBranch: selectedCompany?.isMultiBranch ?? "N",
    },
    validate: (values) => ({
      companyId:
        values?.companyId === "" || values?.companyId === null
          ? "Select Company"
          : null,
      financialYearId:
        values?.financialYearId === "" || values?.financialYearId === null
          ? "Select Financial Year"
          : null,
      branchId:
        values.isMultiBranch === "Y" &&
        (values?.branchId === "" || values?.branchId === null)
          ? "Select Branch"
          : null,
    }),
  })

  useEffect(() => {
    const values = {
      companyId: selectedCompany?.company?.companyId ?? "",
      financialYearId: selectedCompany?.selectedFinancialYear?.fyId ?? "",
      branchId: selectedCompany?.selectedBranch?.branchId ?? "",
      isMultiBranch: selectedCompany?.isMultiBranch ?? "N",
    }
    handleCompanyChange(values.companyId)
    form.setValues(values)
    form.resetDirty(values)
  }, [])

  const handleUpdateSelection = async (values: CompanySelectionForm) => {
    const result = await dispatch(
      updateCompanySelectionChangeAction(userToken, values),
    )
    if (result?.status === "Ok" && result?.data.responseStatus === "Ok") {
      close()
      navigate("/")
    } else {
      notifications.show({
        title: "Update Failed",
        message: result?.errorMessage ?? "Company Selection Update Failed",
        autoClose: 5000,
        icon: <IconX />,
        styles: (theme) => ({
          title: { color: "#a22e10" },
          description: { color: theme.black },
        }),
      })
    }
  }

  const companyAutoCompleteData = userAllowedCompanies.map((comp, i) => {
    let companyObj: companySelectInterface = {
      value: comp.companyId,
      label: comp.companyName,
    }
    return companyObj
  })

  const handleCompanyChange = (selectedCompany: string | null) => {
    // console.log(selectedCompany)
    if (selectedCompany) {
      const selectCompanyObj = userAllowedCompanies.find(
        (comp) => comp.companyId === selectedCompany,
      )
      if (selectCompanyObj) {
        const fyData = selectCompanyObj.allowedFinancialYears.map((fy) => {
          return { value: fy.fyId, label: fy.fyear }
        })
        // console.log(fyData)
        setFinancialYearData(fyData)
        if (selectCompanyObj.isMultiBranch === "Y") {
          const branchDataArr = selectCompanyObj.allowedBranches.map(
            (branch) => {
              return { value: branch.branchId, label: branch.branchName }
            },
          )
          setBranchData(branchDataArr)
        } else {
          setBranchData([])
        }
        form.setValues({
          ...form.values,
          companyId: selectCompanyObj.companyId,
          financialYearId: "",
          branchId: "",
          isMultiBranch: selectCompanyObj.isMultiBranch,
        })
      }
    } else {
      form.setValues({
        ...form.values,
        companyId: "",
        financialYearId: "",
        branchId: "",
        isMultiBranch: "N",
      })
    }
  }
  return (
    <form onSubmit={form.onSubmit((values) => handleUpdateSelection(values))}>
      <Select
        mt="1rem"
        label="Select Company"
        searchable
        clearable
        allowDeselect
        data={companyAutoCompleteData}
        {...form.getInputProps("companyId")}
        onChange={handleCompanyChange}
      />
      <Select
        mt="1rem"
        label="Select Financial Year"
        searchable
        clearable
        allowDeselect
        data={financialYearData}
        {...form.getInputProps("financialYearId")}
      />
      {form?.values?.isMultiBranch === "Y" && (
        <Select
          mt="1rem"
          label="Select Branch"
          searchable
          clearable
          allowDeselect
          data={branchData}
          {...form.getInputProps("branchId")}
        />
      )}
      <Group position="center" mt="2rem">
        <Button
          size="md"
          type="submit"
          color={theme.colors[theme.primaryColor][6]}
          {...(updateCompanyChangeLoading ? { loading: true } : {})}
        >
          Update Selection
        </Button>
        <Button
          size="md"
          onClick={close}
          variant="outline"
          color={theme.colors[theme.primaryColor][6]}
        >
          Cancel
        </Button>
      </Group>
    </form>
  )
}

export default CompanyChangeForm
