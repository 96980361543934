import { postApiCall, apiResponseType } from "./apiService"

// Masters
// API Calls For Party Master
export const getPartyMasterList = async (
  payload = {},
  userToken = "",
): Promise<apiResponseType> => {
  return await postApiCall("common/master/getParties", payload, userToken)
}

// API Calls For Party Master
export const getUsersForAutocomplete = async (
  payload = {},
  userToken = "",
): Promise<apiResponseType> => {
  return await postApiCall(
    "common/master/getUsersForAutocomplete",
    payload,
    userToken,
  )
}

// Common Backend API Calls
export const backendApiCall = async (
  method = "",
  payload = {},
  userToken = "",
  isFormMultpart = false,
): Promise<apiResponseType> => {
  try {
    if (method === "") {
      throw new Error("Missing Method Name")
    }
    return await postApiCall(method, payload, userToken, isFormMultpart)
  } catch (err) {
    throw err
  }
}
