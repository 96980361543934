import { useState } from "react";
import {
  createStyles,
  Container,
} from "@mantine/core";
import LoginForm from "./LoginForm";
import ForgotPasswordForm from "./ForgotPasswordForm";

const useStyles = createStyles((theme) => ({
  loginBg: {
    backgroundImage: `url("/assets/images/login-bg.jpg")`,
    backgroundPosition:"center",
    backgroundRepeat:"no-repeat",
    backgroundSize: "cover",
    opacity: 0.2,
    position: "fixed",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
  },
}))

const Login = () => {
  const [formType, setFormType] = useState(1);
  const { classes } = useStyles();
  return (
    <>
      <Container className={classes.loginBg} fluid></Container>
      <Container size={420} my={100}>
        {formType === 1 ? 
        <LoginForm setFormType={setFormType}/>
        :
        <ForgotPasswordForm setFormType={setFormType}/>
        }
      </Container>
    </>
  )
}

export default Login

