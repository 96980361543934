import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  createStyles,
  Box,
  Group,
  Loader,
  Text,
  rem,
} from "@mantine/core";
import { useDisclosure } from '@mantine/hooks';
import { RootState } from "../../../redux/store";
import { useAppDispatch } from "../../../redux/hooks";
import { getUserCompanyAccessAction } from "../../../redux/actions/userAuthActions";
import CompanyChangeModal from "./CompanyChangeModal";


const useStyles = createStyles((theme) => ({
  companyDetailsContainer: {
    padding: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.primaryColor,
    backgroundColor: theme.fn.variant({
      variant: "light",
      color: theme.primaryColor,
    }).background,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[8]
          : theme.primaryColor,
      cursor:"pointer"
    },
  },
  icon: {
    color: theme.fn.variant({
      variant: "dark",
      color: theme.primaryColor,
    }).background,
    backgroundColor: theme.fn.variant({
      variant: "light",
      color: theme.primaryColor,
    }).background,
  },
  module: {
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    transition: "background-color 100ms ease",

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[8]
          : theme.primaryColor,
    },
  },

  menuDropdown: {
    [theme.fn.smallerThan("xs")]: {
      width: `${rem(380)} !important`,
      marginLeft: "1rem",
      marginRight: "2rem",
      left: "0 !Important",
      border: "1px solid red",
    },
    borderColor: theme.fn.variant({
      variant: "filled",
      color: theme.primaryColor,
    }).background,
  },
  moduleListActive: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.primaryColor,
  },
}))

interface UserAllowedCompaniesProps {};

const UserAllowedCompanies: React.FC<UserAllowedCompaniesProps> = () => {
  const { classes, theme, cx } = useStyles();
  const {
    isAuth,
    userToken,
    isCompanyLoading,
    isCompanyLoaded,
    selectedCompany,
    companyAllowedError,
  } = useSelector((state: RootState) => state.user);
  const [opened, { open, close }] = useDisclosure(false);
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    const getUserAllowedCompanies = async () => {
      if (isAuth) await dispatch(getUserCompanyAccessAction(userToken))
    }
    if(!isCompanyLoaded && !isCompanyLoading)
        getUserAllowedCompanies();

  }, []);
  
  const companyChangeHandler = () => {
    open();
  }
  return (
    <Box className={classes.companyDetailsContainer}>
      {companyAllowedError !== "" && <Text c="#a22e10">Module Error...</Text>}
      {isCompanyLoading ? (
        <Loader />
      ) : (
        <Group noWrap>
          <div onClick={()=>{companyChangeHandler();}}>
            <Text fz="md" tt="uppercase" fw={700} c={theme.colors[theme.primaryColor][6]}>
              {selectedCompany?.company.companyName}
            </Text>
            <Group noWrap spacing="sm">
              <Text fz="sm" tt="uppercase" fw={500} c="filled">
                FY - {selectedCompany?.selectedFinancialYear.fyear}
              </Text>
              { selectedCompany?.isMultiBranch === 'Y' && 
                <Text fz="sm" tt="uppercase" fw={500} c={theme.colors[theme.primaryColor][6]} variant="dimmed">
                  |  {selectedCompany?.selectedBranch.branchName}
                </Text>
              }
            </Group>
          </div>
        </Group>
      )}
      <CompanyChangeModal opened={opened} open={open} close={close} />
    </Box>
    
  )
}

export default UserAllowedCompanies;