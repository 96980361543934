import { createSlice } from "@reduxjs/toolkit";
import { IFormState } from "../../../../../typesDefs/interfaces/modules/pla/masters/siteMaster";

const initialState:IFormState = {
    isEditMode:false,
    selectedRowId:null,
    selectedRow:null
}

export const plaSiteSlice = createSlice({
    name:'plaSite',
    initialState,
    reducers:{
        setSiteEditMode: (state, {payload}) => {
            state.isEditMode = true;
            state.selectedRowId = payload.siteId;
            state.selectedRow = payload
        },
        resetSiteEditMode: (state) => {
            state.isEditMode = false;
            state.selectedRowId = null;
            state.selectedRow = null;
        }
    }
});

const { reducer , actions } = plaSiteSlice;

export const { setSiteEditMode, resetSiteEditMode } = actions;

export default reducer;

