import { lazy } from "react";
import Login from "../../pages/login/Login";
import PrivateRoute from "../../components/PrivateRoute";
import Home from "../../pages/home/Home";
import Support from "../../pages/modules/support/Support";
import Ocgc from '../../pages/modules/ocgc/Ocgc';
import Pla from "../../pages/modules/pla/Pla";

import SupportMaster from "../../pages/modules/support/masters/SupportMaster";
import OcgcMaster from "../../pages/modules/ocgc/master/OcgcMaster";
import PlaMaster from "../../pages/modules/pla/master/PlaMaster";
import OcgcTransactions from "../../pages/modules/ocgc/transactions/OcgcTransactions";
import PlaTransactions from "../../pages/modules/pla/transactions/PlaTransactions";

const UserGroupMaster = lazy(() => import('../../pages/modules/support/masters/userGroupMaster/UserGroupMaster'));
const UserMaster = lazy(() => import('../../pages/modules/support/masters/userMaster/UserMaster'));

// OCGC Module
const PackingTypeMaster = lazy(() => import('../../pages/modules/ocgc/master/packingTypeMaster/PackingTypeMaster'));
const ColorMaster = lazy(() => import('../../pages/modules/ocgc/master/colorMaster/ColorMaster'));
const SizeMaster = lazy(() => import('../../pages/modules/ocgc/master/sizeMaster/SizeMaster'))
const OrderSummary = lazy(() => import('../../pages/modules/ocgc/transactions/orderSummary/OrderSummary'));

// PLA Module 
const SiteMaster = lazy(() => import('../../pages/modules/pla/master/siteMaster/SiteMaster'));
const VehicleTypeMaster = lazy(() => import('../../pages/modules/pla/master/vehicleTypeMaster/VehicleTypeMaster'));
const VehicleMaster = lazy(() => import('../../pages/modules/pla/master/vehicleMaster/VehicleMaster'))
const GoodsReceipts = lazy(() => import('../../pages/modules/pla/transactions/goodsReceipts/GoodsReceipts'));
const VehicleTransits = lazy(() => import('../../pages/modules/pla/transactions/vehicleTransits/VehicleTransits'));
import FuelVoucher from "../../pages/modules/pla/transactions/fuelVoucher/FuelVoucher";

export const routes = [
  { path: "/login", element: <Login /> },
  {
    path: "/",
    element: (
      <PrivateRoute>
        <Home />
      </PrivateRoute>
    ),children:[
    { 
      path:"/support",
      element: <Support />,
      children:[
        {
          path:"/support/master",
          element: <SupportMaster />,
          children:[
          { path: "/support/master/user-group-master", element: <UserGroupMaster /> },
          { path: "/support/master/user-master", element: <UserMaster /> }
        ]}
      ],
      
    },
    { 
      path:"/ocgc",
      element: <Ocgc />,
      children:[
        {
          path:"/ocgc/master",
          element: <OcgcMaster />,
          children:[
          { path: "/ocgc/master/packing-type-master", element: <PackingTypeMaster /> },
          { path: "/ocgc/master/color-master", element: <ColorMaster /> },
          { path: "/ocgc/master/size-master", element: <SizeMaster /> },
        ]},
        {
          path:"/ocgc/transactions",
          element: <OcgcTransactions />,
          children:[
          { path: "/ocgc/transactions/order-summary", element: <OrderSummary /> },
        ]}
      ],
      
    },
    
    { 
      path:"/pla",
      element: <Pla />,
      children:[
        {
          path:"/pla/master",
          element: <PlaMaster />,
          children:[
            { path: "/pla/master/site-master", element: <SiteMaster /> },
            { path: "/pla/master/vehicle-type-master", element: <VehicleTypeMaster /> },
            { path: "/pla/master/vehicle-master", element: <VehicleMaster /> },
        ]},
        {
          path:"/pla/transactions",
          element: <PlaTransactions />,
          children:[
          { path: "/pla/transactions/goods-receipts", element: <GoodsReceipts /> },
          { path: "/pla/transactions/vehicle-transits", element: <VehicleTransits /> },
          { path: "/pla/transactions/fuel-voucher", element: <FuelVoucher /> },
        ]}
      ],
      
    }

    ]
  },
];
