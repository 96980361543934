import { ErrorBoundary } from "react-error-boundary"
import {
  MantineProvider,
  ColorSchemeProvider,
  ColorScheme,
} from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { useHotkeys, useLocalStorage } from "@mantine/hooks";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import Entry from "./Entry"
import Fallback from "./components/shared/Fallback"

import "./App.scss"

function App() {
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: "mantine-color-scheme",
    defaultValue: "light",
    getInitialValueInEffect: true,
  })
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"))

  useHotkeys([["mod+J", () => toggleColorScheme()]]);
  const queryClient = new QueryClient();

  return (
    <ErrorBoundary
      FallbackComponent={Fallback}
      onReset={(details) => {
        // Reset the state of your app so the error doesn't happen again
      }}
    >
      <ColorSchemeProvider
        colorScheme={colorScheme}
        toggleColorScheme={toggleColorScheme}
      >
        <MantineProvider
          theme={{
            colorScheme,
            colors: {
              brand: [
                "#FDECE8",
                "#f8c6b9",
                "#f4a08b",
                "#ef7a5d",
                "#ea542e",
                "#d13b15",
                "#a22e10",
                "#74210b",
                "#461407",
                "#170702",
              ],
            },
            primaryColor: "brand",
            primaryShade: { light: 6, dark: 8 },
          }}
          withGlobalStyles
          withNormalizeCSS
        >
          <Notifications />
          <QueryClientProvider client={queryClient}>
            <Entry />
          </QueryClientProvider>
        </MantineProvider>
      </ColorSchemeProvider>
    </ErrorBoundary>
  )
}

export default App
