import { Modal } from "@mantine/core";
import CompanyChangeForm from "./CompanyChangeForm";

interface CompanyChangeModelProps {
    opened:boolean,
    open:(() => void),
    close:(() => void)
}


const CompanyChangeModal:React.FC<CompanyChangeModelProps> = ({opened, open, close}) => {
    
  return (
    <Modal opened={opened} onClose={close} title="Change Company" centered size="" keepMounted={false}>
        {opened && <CompanyChangeForm close={close} />}
    </Modal>
  )
}

export default CompanyChangeModal