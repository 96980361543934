import { apiUrl } from "../../configs/apiConfig";
import axios,{AxiosError} from "axios";

type headerTypes = {
    'Content-Type':string,
    'Authorization'?:string
}

export interface apiResponseType {
    status: string,
    message: string,
    data: any | null,
    errorMessage: string | null
}

export const postApiCall = async (eventName:string, payloadData:any, token = '', isFormMultpart = false) => {
    let res;
    let headers:headerTypes = {'Content-Type' : 'application/json'};
    if(isFormMultpart)
        headers = {'Content-Type' : "multipart/form-data"};
    
    if(token !== ''){
        headers = {...headers, Authorization: `Bearer ${token}`}
    }
    try{
        let url = apiUrl+eventName;
        const response = await axios.post(url,payloadData,{headers});
        if(response.data.responseStatus === "Ok"){
            res = {status: 'Ok', message: 'Api Response Successful', data: response.data, errorMessage: null};
        }else{
            res = {status: 'Error', message: 'Api Response Failed', data: null, errorMessage: response.data.message};
        }
        return res;
    }catch(error){
        const err = error as AxiosError
        console.log('Api Error Catch : ', err);
        return res = {status:'Error', message:'Api Response Failed', data:null, errorMessage: err.message ?? 'API Failed'};
    }
}

export const getApiCall = async (eventName:string,params:any | undefined,token='') => {
    let res;
    let headers:headerTypes = {'Content-Type':'application/json'};
    if(token !== ''){
        headers = {...headers, Authorization:`Bearer ${token}`};
    }
    try{
        let url = apiUrl+eventName;
        const response = await axios.get(url,{params,headers});
        if(response.data.responseStatus === "Ok"){
            res = {status:'Ok', message:'Api Response Successful', data:response.data};
        }else{
            res = {status:'Error', message:'Api Response Failed', data:null, errorMessage: response.data.message};
        }
        return res;
    }catch(error){
        const err = error as AxiosError
        console.log('Api Error Catch : ', err);
        return res = {status:'Error', message:'Api Response Failed', data:null, errorMessage: err.message ?? 'API Failed'};
    }
}