import { createStyles } from "@mantine/core"

export const useFormStyles = createStyles((theme) => ({
  input: {
    input: {
      fontSize: "1.2rem",
      padding: "1rem",
    },
    label: {
      fontSize: "1rem",
      marginBottom: "0.5rem",
      marginTop:"0.5rem"
    },
  },
  formError: {
    fontSize: "1rem",
    margin: "0.5rem",
    textAlign: "center",
    justifyContent: "center",
  },
}))
