import { useEffect } from "react"
import { useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import {
  createStyles,
  Loader,
  rem,
  Text,
  Tooltip,
  Title,
  UnstyledButton,
} from "@mantine/core"

import {
  IconBrandLinktree,
  IconArrowsMove,
  IconReportSearch,
  IconTools,
  IconFileSettings,
  IconSettingsAutomation,
  IconSettings,
  IconCheckupList,
} from "@tabler/icons-react"
import { RootState } from "../../../redux/store"
import { useAppDispatch } from "../../../redux/hooks"
import { getUserMenuAccessAction } from "../../../redux/actions/userAuthActions"
import {
  setSelectedMenuModule,
  setSelectedMenu,
} from "../../../redux/slice/userSlice"
import { toggleNavbar } from "../../../redux/slice/appSlice"

const moduleIconData = [
  { icon: IconBrandLinktree, label: "Master", menuItems: [] },
  { icon: IconArrowsMove, label: "Transactions", menuItems: [] },
  { icon: IconReportSearch, label: "Reports", menItems: [] },
  { icon: IconTools, label: "Utitility", menuItems: [] },
  { icon: IconFileSettings, label: "Configs", menuItems: [] },
  { icon: IconSettingsAutomation, label: "System", menuItems: [] },
  { icon: IconSettings, label: "Support", menuItems: [] },
  { icon: IconCheckupList, label: "Task Management", menuItems: [] },
]

const useStyles = createStyles((theme) => ({
  aside: {
    flex: `0 0 ${rem(60)}`,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRight: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
  },
  main: {
    flex: 1,
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
  },

  mainLink: {
    width: rem(44),
    height: rem(44),
    borderRadius: theme.radius.md,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors.gray[0],
    },
  },

  mainLinkActive: {
    "&, &:hover": {
      backgroundColor: theme.fn.variant({
        variant: "light",
        color: theme.primaryColor,
      }).background,
      color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
        .color,
    },
  },

  title: {
    boxSizing: "border-box",
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    marginBottom: theme.spacing.xl,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    padding: theme.spacing.md,
    paddingTop: rem(18),
    height: rem(60),
    borderBottom: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
  },

  module: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: rem(100),
    borderBottom: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
  },

  logo: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    height: rem(60),
    paddingTop: theme.spacing.md,
    borderBottom: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
    marginBottom: theme.spacing.xl,
  },

  link: {
    boxSizing: "border-box",
    display: "block",
    textDecoration: "none",
    borderTopRightRadius: theme.radius.md,
    borderBottomRightRadius: theme.radius.md,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    padding: `0 ${theme.spacing.md}`,
    fontSize: theme.fontSizes.sm,
    marginRight: theme.spacing.md,
    fontWeight: 500,
    height: rem(44),
    lineHeight: rem(44),
    overflowWrap: "break-word",

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors.gray[1],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },
  },

  linkActive: {
    "&, &:hover": {
      borderLeftColor: theme.fn.variant({
        variant: "filled",
        color: theme.primaryColor,
      }).background,
      backgroundColor: theme.fn.variant({
        variant: "filled",
        color: theme.primaryColor,
      }).background,
      color: theme.white,
    },
  },
}))
const UserAllowedMenus = () => {
  const { classes, cx } = useStyles()
  const {
    isAuth,
    userToken,
    isMenuLoading,
    isModuleLoaded,
    selectedModule,
    isMenuLoaded,
    userMenus,
    selectedMenuModule,
    menuLoadingError,
  } = useSelector((state: RootState) => state.user)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  useEffect(() => {
    const getUserAllowedMenus = async () => {
      const forModuleId = selectedModule?.moduleCode ?? ""
      if (isAuth && isModuleLoaded)
        await dispatch(getUserMenuAccessAction(userToken, forModuleId))
    }
    if (isModuleLoaded && !isMenuLoaded && !isMenuLoading) getUserAllowedMenus()
  }, [selectedModule])

  const mainLinks = userMenus.map((module) => {
    const moduleIconObj = moduleIconData.find(
      (mod) => mod.label === module.label,
    )
    return (
      <Tooltip
        label={module.label}
        position="right"
        withArrow
        transitionProps={{ duration: 0 }}
        key={module.label}
      >
        <UnstyledButton
          onClick={() =>
            dispatch(setSelectedMenuModule({ selectedMenuModule: module }))
          }
          className={cx(classes.mainLink, {
            [classes.mainLinkActive]:
              module.label === selectedMenuModule?.label,
          })}
        >
          {moduleIconObj ? (
            <moduleIconObj.icon size="1.4rem" stroke={1.5} />
          ) : (
            <Text weight={500} size="md" sx={{ lineHeight: 1 }} mr={3}>
              {module?.label?.substring(1, 1) ?? "Guest"}
            </Text>
          )}
        </UnstyledButton>
      </Tooltip>
    )
  })

  const menuLinks = selectedMenuModule?.items?.map((menu) => {
    let path = selectedModule?.path ?? "/"
    if (selectedMenuModule.path) {
      path += selectedMenuModule.path
    }
    if (menu?.path) {
      path += menu?.path
    }
    let isActiveMenu = false
    if (path === window.location.pathname) {
      isActiveMenu = true
    }
    return (
      <Link
        to={path}
        className={cx(classes.link, {
          [classes.linkActive]: isActiveMenu,
        })}
        onClick={async (event) => {
          event.preventDefault()
          await dispatch(setSelectedMenu({ selectedMenu: menu }))
          await dispatch(toggleNavbar())
          navigate(path)
        }}
        key={menu.label}
      >
        {menu.label}
      </Link>
    )
  }) ?? <></>

  return (
    <>
      {menuLoadingError !== "" && <Text c="#a22e10">Module Error...</Text>}
      {isMenuLoading ? (
        <Loader />
      ) : (
        <>
          <div className={classes.aside}>
            <div className={classes.logo}></div>
            {mainLinks}
          </div>
          <div className={classes.main}>
            <Title order={4} className={classes.title}>
              {selectedMenuModule?.label ?? ""}
            </Title>
            {menuLinks}
          </div>
        </>
      )}
    </>
  )
}

export default UserAllowedMenus
