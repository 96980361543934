import React, { forwardRef, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import {
  Box,
  Grid,
  Group,
  Loader,
  MantineColor,
  Select,
  SelectItemProps,
  Text,
  ThemeIcon,
} from "@mantine/core"
import { IconRefresh } from "@tabler/icons-react"
import { useFormStyles } from "../../../../../styles/formStyles"
import { getSiteMasterList } from "../../../../../service/apiCalls/plaApiCalls"
import { RootState } from "../../../../../redux/store"

interface ItemProps extends SelectItemProps {
  color: MantineColor
  siteName: string
}

const AutoCompleteItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ siteName, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others} style={{ overflow: "auto" }}>
      <Box sx={{ width: "30rem", flexWrap: "nowrap" }}>
        <Grid>
          <Grid.Col span={6}>
            <Text>{siteName}</Text>
          </Grid.Col>
        </Grid>
      </Box>
    </div>
  ),
)

interface AutocompletePayload {
  getFullDetails: string
  status: string
  pageNo: number
  perPageRecords: number
  siteName?: string
  type?: string
}

interface AutocompleteProps {
  label?: string
  placeHolder?: string
  isDisabled?: boolean
  onChange?: () => void
}

const SiteDropDown: React.FC<AutocompleteProps> = (props) => {
  const {
    label = "Select Site",
    placeHolder = "Select Site",
    isDisabled = false,
    ...others
  } = props
  const { userToken } = useSelector((state: RootState) => state.user)
  const { classes, theme } = useFormStyles()
  const [isRemoteData, setIsRemoteData] = useState(false)
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [sitesData, setSitesData] = useState({
    totalRecords: 0,
    sites: [],
  })

  const getSitesData = async (
    payload: AutocompletePayload = {
      getFullDetails: "N",
      status: "A",
      pageNo: 1,
      perPageRecords: 1000,
      siteName: "",
      type: "M",
    },
  ) => {
    setIsDataLoading(true)
    const result = (await getSiteMasterList(payload, userToken)) ?? []
    setIsDataLoading(false)
    if (result.status === "Ok") {
      setSitesData(result?.data)
      if (result?.data?.totalRecords && result?.data?.totalRecords > 1000) {
        setIsRemoteData(true)
      }
    } else throw new Error(result.errorMessage ?? "API Call Failed")
  }

  const reloadData = async () => {
    await getSitesData({
      getFullDetails: "N",
      status: "A",
      type:'M',
      pageNo: 1,
      perPageRecords: 1000,
    })
  }

  const handleChange = async (value: string) => {
    if (isRemoteData) {
      await getSitesData({
        getFullDetails: "N",
        status: "A",
        pageNo: 1,
        perPageRecords: 1000,
        siteName: value,
        type: "M",
      })
    }
  }

  useEffect(() => {
    getSitesData()
  }, [])

  return (
    <Box
      sx={(theme) => ({
        width: "100%",
        alignItems: "flex-end",
        paddingBottom: "0.5rem",
      })}
    >
      <Group sx={(theme) => ({ width: "100%", alignItems: "flex-end" })}>
        <Select
          className={classes.input}
          label={label}
          placeholder={placeHolder}
          searchable
          clearable
          itemComponent={AutoCompleteItem}
          onChange={handleChange}
          data={sitesData?.sites ?? []}
          disabled={isDisabled}
          w="85%"
          rightSection={isDataLoading ? <Loader size="1rem" /> : null}
          filter={(value, item) =>
            item.siteName.toLowerCase().includes(value.toLowerCase().trim())
          }
          {...others}
          withinPortal={true}
        />
        <ThemeIcon variant="outline" bottom="0">
          <IconRefresh onClick={reloadData} />
        </ThemeIcon>
      </Group>
    </Box>
  )
}

export default SiteDropDown
