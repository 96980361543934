
export const notificationSucessProps = {
    autoClose: 5000,
    color: "green",
    withBorder: true,
    withCloseButton: true,
    styles: () => ({
        title: { color: "#39862D" },
        description: { color: "000000" },
        closeButton: {
            color: "#FFFFFF",
            backgroundColor:"#A22E10",
            '&:hover': { backgroundColor: "#37B24D" },
        },
    }),
};


export const notificationFailedProps = {
    autoClose: 5000,
    color: "#A22E10",
    withBorder: true,
    withCloseButton: true,
    styles: () => ({
        title: { color: "#A22E10" },
        description: { color: "000000" },
        closeButton: {
            color: "#FFFFFF",
            backgroundColor:"#A22E10",
            '&:hover': { backgroundColor: "#37B24D" },
        },
    }),
};

export const notificationWarningProps = {
    autoClose: 5000,
    color: "orange",
    withBorder: true,
    withCloseButton: true,
    styles: () => ({
        title: { color: "#E8590C" },
        description: { color: "000000" },
        closeButton: {
            color: "#FFFFFF",
            backgroundColor:"#E8590C",
            '&:hover': { backgroundColor: "#37B24D" },
        },
    }),
};