import { createSlice } from "@reduxjs/toolkit";
import { AppStateInterface } from "../../typesDefs/interfaces/appState";

const initialState:AppStateInterface = {
    isLoading: false,
    appToken:'',
    appTokenError:'',
    isNavbarOpen:false
}

export const appSlice = createSlice({
    name:'api',
    initialState,
    reducers: {
        appTokenPending: (state) => {
            state.isLoading = true;
        },
        appTokenSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.appToken = payload.appToken;
            state.appTokenError = "";
            localStorage.setItem('appToken',payload.appToken);
        },
        appTokenFail: (state, { payload }) => {
            state.isLoading = false;
            state.appToken = '';
            state.appTokenError = payload;
        },
        appTokenReset:(state) => {
            state.isLoading = false;
            state.appToken = '';
            state.appTokenError = '';
        },
        toggleNavbar:(state) => {
            state.isNavbarOpen = !state.isNavbarOpen;
        }
    }
})

const { reducer, actions } = appSlice;

export const { appTokenPending, appTokenSuccess, appTokenFail, appTokenReset, toggleNavbar } = actions;

export default reducer;